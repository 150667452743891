<template>
  <v-stepper-content :step="step">
    <div class="text-h5 font-weight-medium">{{ $t('BasicInfo') }}</div>
    <v-row class="align-center">
      <v-col cols="12" class="d-flex no-gutters" sm="auto">
        <MenuGroup>
          <DvrTimePicker
            :label="$t('CheckIn')"
            border-radius="left"
            :min="minCheckin"
            :value.sync="checkInTime"
          />
          <DvrTimePicker
            :label="$t('CheckOut')"
            border-radius="right"
            :max="maxCheckout"
            :value.sync="checkOutTime"
          />
        </MenuGroup>
      </v-col>
      <v-col cols="12" sm="auto">
        <GuestsSelect :value.sync="guests" :readonly="hasChekinVerification" />
      </v-col>
    </v-row>
    <div class="text-h5 font-weight-medium my-4">{{ $t('Guest Details') }}</div>
    <div v-if="hasChekinVerification">
      <span v-if="chekinCompleteError" class="error--text">
        {{ chekinCompleteError }}
      </span>
      <chekin-guest-sdk
        :api-key="chekinGuestKey"
        :reservation-id="checkInVerificationIdentifier"
        :step="step"
        :pre-check-in="preCheckIn"
      />
    </div>
    <v-form v-else ref="form">
      <v-row v-for="(guest, ind) in guestsDetails" :key="'guest' + ind">
        <v-col cols="12">
          <div class="font-weight-medium">Guest #{{ ind + 1 }}</div>
        </v-col>
        <v-col cols="12" sm="3" class="py-1">
          <v-text-field
            v-model="guestsDetails[ind].full_name"
            :rules="checkinInfo.optional_guest_names ? [] : [required]"
            outlined
            dense
            :label="$t('Full Name')"
          />
        </v-col>
        <v-col v-if="guestPassport" cols="12" sm="3" class="py-1">
          <v-text-field
            v-model="guestsDetails[ind].passport"
            :rules="[required]"
            outlined
            dense
            :label="$t('Passport Number')"
          />
        </v-col>
        <v-col v-if="guestId" cols="12" sm="3" class="py-1">
          <v-text-field
            v-model="guestsDetails[ind].id"
            :rules="[required]"
            outlined
            dense
            :label="$t('ID Number')"
          />
        </v-col>
        <v-col v-if="guestPhone" cols="12" sm="3" class="py-1">
          <v-text-field
            v-model.number="guestsDetails[ind].phone_number"
            :rules="[required]"
            outlined
            dense
            type="number"
            :label="$t('Phone Number')"
          />
        </v-col>
        <v-col v-if="guestEmail" cols="12" sm="3" class="py-1">
          <v-text-field
            v-model="guestsDetails[ind].email"
            :rules="[required]"
            outlined
            dense
            :label="$t('Email')"
          />
        </v-col>
        <v-col v-if="guestAddress" cols="12" sm="6" class="py-1">
          <v-text-field
            v-model="guestsDetails[ind].address"
            :rules="[required]"
            outlined
            dense
            :label="$t('Address')"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col>
        <div class="mb-2">{{ $t('What is the purpose of your visit?') }}</div>
        <v-radio-group
          v-model="clientInfo.trip_purpose"
          mandatory
          class="font-weight-medium mt-0 pt-0"
          style="margin-right: -15px"
          hide-details
          row
        >
          <v-radio :label="$t('Leisure')" value="leisure" />
          <v-radio :label="$t('Business')" value="business" />
        </v-radio-group>
      </v-col>
    </v-row>
  </v-stepper-content>
</template>

<script>
import MenuGroup from '@/components/common/menu-group'
import GuestsSelect from '@/components/common/guests-select'
import formRules from '@/components/mixins/form-rules-mixin'
import DvrTimePicker from '@/components/common/dvr-time-picker'
import ChekinGuestSdk from '@/components/common/chekin-guest-sdk.vue'

export default {
  name: 'GuestsInfo',
  components: { DvrTimePicker, GuestsSelect, MenuGroup, ChekinGuestSdk },
  mixins: [formRules],
  props: ['preCheckIn', 'step'],
  data() {
    return {
      minCheckin: this.preCheckIn.min_check_in_time_string,
      maxCheckout: this.preCheckIn.max_check_out_time_string,
      clientInfo: this.preCheckIn.client_info,
      guests: {
        adults: this.preCheckIn.guest_count_info.adults || 1,
        kids: this.preCheckIn.guest_count_info.kids,
        babies: this.preCheckIn.guest_count_info.babies,
      },
      guestNames: this.preCheckIn.guest_count_info.guests,
      guestsDetails: [],
      checkInTime: this.preCheckIn.times_info.requested_check_in_time,
      checkOutTime: this.preCheckIn.times_info.requested_check_out_time,
      checkinInfo: this.preCheckIn.checkin_info,
      chekinCompleteError: null,
    }
  },
  computed: {
    hasChekinVerification() {
      return !!this.chekinGuestKey
    },
    checkInVerificationIdentifier() {
      return this.preCheckIn.chekin_verification_identifier
    },
    allGuestDetails() {
      return this.checkinInfo.require_all_guest_details
    },
    guestPassport() {
      return this.allGuestDetails && this.checkinInfo.require_guest_passport
    },
    guestId() {
      return this.allGuestDetails && this.checkinInfo.require_guest_id
    },
    guestAddress() {
      return this.allGuestDetails && this.checkinInfo.require_guest_address
    },
    guestEmail() {
      return this.allGuestDetails && this.checkinInfo.require_guest_email
    },
    guestPhone() {
      return this.allGuestDetails && this.checkinInfo.require_guest_phone
    },
  },
  watch: {
    guests: {
      handler() {
        this.initializeGuestDetails()
      },
      deep: true,
    },
  },
  mounted() {
    if (this.preCheckIn.guests_details.length) {
      this.guestsDetails = this.preCheckIn.guests_details
    } else {
      this.initializeGuestDetails()
    }
  },
  methods: {
    initializeGuestDetails() {
      const newCount =
        this.guests.adults + this.guests.kids + this.guests.babies
      const countChange = newCount - this.guestsDetails.length

      if (countChange > 0) {
        this.guestsDetails.push(
          ...new Array(countChange).fill(null).map(() => ({}))
        )
      } else if (countChange < 0) {
        this.guestsDetails.splice(newCount, Math.abs(countChange))
      }

      const firstGuest = this.guestsDetails[0] || {}
      if (!firstGuest.full_name) firstGuest.full_name = this.guestNames[0]
      if (!firstGuest.phone_number) {
        firstGuest.phone_number = Number(this.clientInfo.phone_number)
      }
    },
    async validate() {
      if (this.hasChekinVerification) {
        this.chekinCompleteError = null

        const data = await this.$store.dispatch(
          'getChekinReservationStatus',
          this.preCheckIn.reservation_id
        )

        if (
          data.payload.data.verification_details.guests_registration_status !==
          'COMPLETE'
        ) {
          this.chekinCompleteError = 'Please complete the check-in process'
          return
        }

        const payload = {
          times_info: {
            requested_check_in_time: this.checkInTime,
            requested_check_out_time: this.checkOutTime,
          },
          client_info: this.clientInfo,
        }
        this.$emit('step-forward', payload)
      } else if (this.$refs.form.validate()) {
        const payload = {
          times_info: {
            requested_check_in_time: this.checkInTime,
            requested_check_out_time: this.checkOutTime,
          },
          guest_count_info: { ...this.guests, guests: this.guestNames },
          client_info: this.clientInfo,
          guests_details: this.guestsDetails,
        }
        this.$emit('step-forward', payload)
      }
    },
  },
}
</script>

<style scoped></style>
