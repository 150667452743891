<template>
  <PurchaseTable />
</template>

<script>
import PurchaseTable from 'components/store/purchase-table'
export default {
  name: 'PurchaseHistory',
  components: { PurchaseTable },
}
</script>

<style scoped></style>
