<template>
  <div class="locale-changer">
    <v-menu v-model="menu" bottom offset-y>
      <template #activator="{ on }">
        <div class="pointer" :title="currentLocaleDetails.fullName" v-on="on">
          <country-flag
            :country="currentLocaleDetails.country"
            :rounded="true"
            size="normal"
          />
        </div>
      </template>
      <v-list>
        <v-list-item-group v-model="currentLocale" @change="onLocaleChange">
          <v-list-item
            v-for="(item, lang) in availableLocales"
            :key="lang"
            :value="lang"
          >
            <div :title="item.fullName">
              <country-flag
                :country="item.country"
                :rounded="true"
                size="normal"
              />
            </div>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag'
import DeviceMixin from 'components/mixins/deviceMixin.js'
import { mapGetters } from 'vuex'

export default {
  name: 'LocaleChanger',
  components: { CountryFlag },
  mixins: [DeviceMixin],
  data() {
    return {
      menu: false,
      availableLocales: {
        es: { country: 'es', fullName: 'Español' },
        en: { country: 'us', fullName: 'English' },
        he: { country: 'il', fullName: 'עברית' },
        ru: { country: 'ru', fullName: 'Русский' },
        fr: { country: 'fra', fullName: 'Français' },
        el: { country: 'gr', fullName: 'Ελληνικά' },
        ge: { country: 'de', fullName: 'Deutsch' },
        it: { country: 'it', fullName: 'Italiano' },
        ar: { country: 'ae', fullName: 'العربية' },
        'zh-Hans': { country: 'cn', fullName: '中文' },
        pt: { country: 'pt', fullName: 'Português' },
      },
    }
  },
  watch: {
    '$i18n.locale'(newLocale, oldLocale) {
      if (newLocale !== oldLocale) {
        this.updatePageDirection()
      }
    },
  },
  computed: {
    ...mapGetters(['user']),
    rtlLanguages() {
      return ['he', 'ar']
    },
    currentLocale: {
      get() {
        return this.$i18n.locale
      },
      set(value) {
        if (value && this.$i18n.locale !== value) {
          this.onLocaleChange(value)
        }
      },
    },
    currentLocaleDetails() {
      return (
        this.availableLocales[this.$i18n.locale] || this.availableLocales['en']
      )
    },
  },
  methods: {
    onLocaleChange(value) {
      if (value === undefined || this.$i18n.locale === value) return
      this.$i18n.locale = value
      this.updateLocale()
    },
    updateLocale() {
      this.$store
        .dispatch('updateLocale', {
          id: this.user.id,
          locale: this.$i18n.locale,
        })
        .catch(error => {
          console.error('Failed to update locale:', error)
        })
    },
    updatePageDirection() {
      const isRtl = this.rtlLanguages.includes(this.$i18n.locale)
      this.$vuetify.rtl = isRtl
      document.dir = isRtl ? 'rtl' : 'ltr'
    },
  },
}
</script>
