<template>
  <vue-phone-number-input
    :value="phone"
    size="lg"
    class="phone-input"
    color="#44a2a2"
    valid-color="#757575"
    :error="error"
    :no-country-selector="showCountrySelector"
    @update="update"
  />
</template>

<script>
export default {
  props: ['value', 'initialPhone', 'error'],
  computed: {
    phone() {
      return this.value && this.value.phoneNumber !== null
        ? this.value.phoneNumber
        : this.initialPhone
    },
    showCountrySelector() {
      return this.phone && this.phone[0] === '+'
    },
  },
  methods: {
    update(value) {
      this.$emit('update:value', value)
      this.$emit('update:error', false)
    },
  },
}
</script>

<style>
.phone-input {
  padding-left: 1px;
}
</style>
