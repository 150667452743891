export default {
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs
    },
    isIPad() {
      return this.$vuetify.breakpoint.sm
    },
    isMobileOrIPad() {
      return this.isMobile || this.isIPad
    },
  },
}
