<template>
  <div class="mb-2 mx-md-4">
    <p class="mb-0 font-weight-bold mb-2">{{ $t(item.text) }}</p>
    <v-textarea
      v-if="item.value === 'feedback'"
      v-model="feedback"
      class="mt-2"
      :placeholder="$t('Feedback')"
      outlined
      hide-details
      @input="update(item.value, feedback)"
    />
    <v-chip-group
      v-else
      v-model="point"
      class="review-chip-group"
      active-class="selected-option"
      @change="update(item.value, point)"
    >
      <v-chip
        v-for="index in marks"
        :key="index"
        outlined
        class="option"
        :value="index"
        label
      >
        <div class="text-center">
          <v-icon>{{ rateIcons[index] }}</v-icon>
          <div class="mt-2">{{ $t(rateLabel[index]) }}</div>
        </div>
      </v-chip>
    </v-chip-group>
  </div>
</template>

<script>
import deviceMixin from 'components/mixins/deviceMixin'

export default {
  name: 'ReviewFormItem',
  mixins: [deviceMixin],
  props: {
    item: Object,
  },
  data() {
    return {
      marks: 5,
      point: null,
      feedback: '',
      rateIcons: {
        5: '😍',
        4: '😀',
        3: '🙂',
        2: '😕',
        1: '😩',
      },
      rateLabel: {
        5: 'Great',
        4: 'Good',
        3: 'Ok',
        2: 'Bad',
        1: 'Terrible',
      },
    }
  },
  methods: {
    update(field, value) {
      this.$emit('update', { field, value })
    },
  },
}
</script>

<style lang="scss">
.review-chip-group .v-slide-group__content {
  justify-content: space-between;
}
.option {
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  height: 70px !important;
  margin: 3px !important;
  font-size: 12px !important;
}
.v-chip.selected-option {
  box-shadow: 0px 5px 10px 0px #4fa9b640;
  border: 1px solid;
  border-color: #4fa9b6 !important;
}
.v-chip.selected-option::before {
  background-color: white !important;
}
</style>
