<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="870.683"
    height="624.819"
    viewBox="0 0 870.683 624.819"
  >
    <g transform="translate(-504.208 -427.602)">
      <path
        class="a"
        d="M775.5,566.882C917.333,495.4,934.541,401.123,890.352,300.255c-31.3-71.443-93.363-87.138-168.682-84.994-89.011,2.144-133.157-56.114-203.909-111.858C471.867,67.242,424.31,19,362.563,36.938c-96.007,27.887-99.625,143.3-133.859,192.615-36.517,55.745-79.48,50-127.05,90.658C-23.73,427.364,59.116,540.668,228.7,566.882S633.677,638.363,775.5,566.882Z"
        transform="translate(463.028 447.109)"
      />
      <g transform="translate(-15.917 -85.695)">
        <g transform="translate(746.142 766.326)">
          <ellipse
            class="b"
            cx="56.159"
            cy="32.423"
            rx="56.159"
            ry="32.423"
            transform="translate(28.198 9)"
          />
          <ellipse
            class="c"
            cx="82.465"
            cy="47.611"
            rx="82.465"
            ry="47.611"
            transform="translate(309.557 160.943)"
          />
          <ellipse
            class="b"
            cx="64.89"
            cy="37.464"
            rx="64.89"
            ry="37.464"
            transform="translate(-157 160.941)"
          />
        </g>
        <g transform="translate(718.011 510.297)">
          <g transform="translate(26.939 3)">
            <g transform="translate(72.556 131.009)">
              <path
                class="d"
                d="M190.961,143.5V300.6c0,1.884,1.247,3.77,3.738,5.208,4.983,2.877,13.061,2.877,18.044,0,2.491-1.438,3.736-3.324,3.736-5.208V143.5Z"
                transform="translate(-190.961 -143.5)"
              />
            </g>
            <path
              class="e"
              d="M311.414,259.77,232.378,68.9H221.369L142.332,259.785a10.1,10.1,0,0,0,7.367,13.77l1.288.256a10.1,10.1,0,0,1,8.057,11.159h0a10.1,10.1,0,0,0,12.993,10.906l3.9-1.2a10.1,10.1,0,0,1,12.169,5.481l3.93,8.667a10.1,10.1,0,0,0,14.349,4.516l15.334-9.092a10.1,10.1,0,0,1,10.3,0l15.334,9.092a10.1,10.1,0,0,0,14.349-4.516l3.932-8.668a10.1,10.1,0,0,1,12.166-5.482l3.907,1.2a10.1,10.1,0,0,0,12.99-10.908v-.006a10.1,10.1,0,0,1,8.051-11.16l1.3-.258A10.1,10.1,0,0,0,311.414,259.77Z"
              transform="translate(-141.558 -47.452)"
            />
            <path
              class="f"
              d="M311.414,259.77,232.378,68.9H221.369L142.332,259.785a10.1,10.1,0,0,0,7.367,13.77l1.288.256a10.1,10.1,0,0,1,8.057,11.159h0a10.1,10.1,0,0,0,12.993,10.906l3.9-1.2a10.1,10.1,0,0,1,12.169,5.481l3.93,8.667a10.1,10.1,0,0,0,14.349,4.516l15.334-9.092a10.1,10.1,0,0,1,10.3,0l15.334,9.092a10.1,10.1,0,0,0,14.349-4.516l3.932-8.668a10.1,10.1,0,0,1,12.166-5.482l3.907,1.2a10.1,10.1,0,0,0,12.99-10.908v-.006a10.1,10.1,0,0,1,8.051-11.16l1.3-.258A10.1,10.1,0,0,0,311.414,259.77Z"
              transform="translate(-141.558 -47.452)"
            />
            <path
              class="g"
              d="M207.2,89.823l8.83-20.06h11.009l14.6,33.994,47.7,115.193a5.849,5.849,0,0,1-4.971,4.723h0a7.635,7.635,0,0,0-6.348,8.42l.17,1.466a7.635,7.635,0,0,1-10.3,8.02l-3.911-1.489a7.634,7.634,0,0,0-9.69,4.027L249.437,255a7.636,7.636,0,0,1-10.887,3.448l-13.1-8.731a7.637,7.637,0,0,0-7.825,0L203,258.446A7.635,7.635,0,0,1,192.109,255l-4.85-10.881a7.636,7.636,0,0,0-9.692-4.027l-3.91,1.489a7.636,7.636,0,0,1-10.3-8.022l.17-1.461a7.634,7.634,0,0,0-6.349-8.42,5.794,5.794,0,0,1-4.233-2.818Z"
              transform="translate(-136.221 -47.049)"
            />
            <path
              class="e"
              d="M288.753,206.707l-61.415-143H216.329l-62.941,143c-2.268,4.6-.972,10.081,4.087,10.911a7.635,7.635,0,0,1,6.349,8.42l-.17,1.463a7.636,7.636,0,0,0,10.3,8.02l3.91-1.489a7.635,7.635,0,0,1,9.692,4.029l4.85,10.88a7.636,7.636,0,0,0,10.887,3.45l14.626-8.73a7.63,7.63,0,0,1,7.825,0l13.1,8.73a7.635,7.635,0,0,0,10.887-3.448l4.851-10.881a7.634,7.634,0,0,1,9.69-4.029l3.911,1.489a7.635,7.635,0,0,0,10.3-8.02l-.17-1.464a7.634,7.634,0,0,1,6.348-8.42h0C289.725,216.788,291.021,211.307,288.753,206.707Z"
              transform="translate(-136.518 -49.886)"
            />
            <path
              class="h"
              d="M288.753,206.707l-61.415-143H216.329l-62.941,143c-2.268,4.6-.972,10.081,4.087,10.911a7.635,7.635,0,0,1,6.349,8.42l-.17,1.463a7.636,7.636,0,0,0,10.3,8.02l3.91-1.489a7.635,7.635,0,0,1,9.692,4.029l4.85,10.88a7.636,7.636,0,0,0,10.887,3.45l14.626-8.73a7.63,7.63,0,0,1,7.825,0l13.1,8.73a7.635,7.635,0,0,0,10.887-3.448l4.851-10.881a7.634,7.634,0,0,1,9.69-4.029l3.911,1.489a7.635,7.635,0,0,0,10.3-8.02l-.17-1.464a7.634,7.634,0,0,1,6.348-8.42h0C289.725,216.788,291.021,211.307,288.753,206.707Z"
              transform="translate(-136.518 -49.886)"
            />
            <path
              class="g"
              d="M210.425,63.708h11.009l44.754,104.209a6.872,6.872,0,0,1-1.128.795l-.342.192a6.883,6.883,0,0,0-3.491,6.584v0a6.881,6.881,0,0,1-8.609,7.236l-2.67-.7a6.88,6.88,0,0,0-7.816,3.4l-3.663,6.831a6.881,6.881,0,0,1-9.25,2.848l-10.107-5.278a6.879,6.879,0,0,0-6.371,0L202.637,195.1a6.88,6.88,0,0,1-9.25-2.848l-3.661-6.831a6.88,6.88,0,0,0-7.818-3.4l-2.667.7a6.88,6.88,0,0,1-8.609-7.236,6.88,6.88,0,0,0-3.492-6.585l-.341-.191a6.814,6.814,0,0,1-1.889-1.595Z"
              transform="translate(-130.614 -49.886)"
            />
            <path
              class="e"
              d="M268.07,154.875,221.907,57.839l-.36-.585a5.872,5.872,0,0,0-10.2,0l-.37.6-46.154,97.023a6.882,6.882,0,0,0,2.491,9.618l.341.191a6.879,6.879,0,0,1,3.492,6.584,6.881,6.881,0,0,0,8.609,7.238l2.667-.7a6.881,6.881,0,0,1,7.818,3.4l3.661,6.831a6.882,6.882,0,0,0,9.25,2.848l10.106-5.278a6.879,6.879,0,0,1,6.371,0l10.107,5.278a6.881,6.881,0,0,0,9.25-2.848l3.663-6.831a6.879,6.879,0,0,1,7.816-3.4l2.67.7a6.882,6.882,0,0,0,8.609-7.238h0a6.883,6.883,0,0,1,3.491-6.584l.342-.192A6.881,6.881,0,0,0,268.07,154.875Z"
              transform="translate(-131.134 -54.297)"
            />
            <path
              class="g"
              d="M268.07,154.875,221.907,57.839l-.36-.585a5.872,5.872,0,0,0-10.2,0l-.37.6-46.154,97.023a6.882,6.882,0,0,0,2.491,9.618l.341.191a6.879,6.879,0,0,1,3.492,6.584,6.881,6.881,0,0,0,8.609,7.238l2.667-.7a6.881,6.881,0,0,1,7.818,3.4l3.661,6.831a6.882,6.882,0,0,0,9.25,2.848l10.106-5.278a6.879,6.879,0,0,1,6.371,0l10.107,5.278a6.881,6.881,0,0,0,9.25-2.848l3.663-6.831a6.879,6.879,0,0,1,7.816-3.4l2.67.7a6.882,6.882,0,0,0,8.609-7.238h0a6.883,6.883,0,0,1,3.491-6.584l.342-.192A6.881,6.881,0,0,0,268.07,154.875Z"
              transform="translate(-131.134 -54.297)"
            />
            <g transform="translate(55.429 285.421)">
              <g transform="translate(8.061)">
                <path
                  class="i"
                  d="M200.457,256.253a7.834,7.834,0,0,0-15.662,0,3.259,3.259,0,0,0,0,.438,4.017,4.017,0,0,0,2.285,2.984,12.261,12.261,0,0,0,11.084,0,4.025,4.025,0,0,0,2.293-2.984,3.259,3.259,0,0,0,0-.438Z"
                  transform="translate(-184.788 -248.638)"
                />
                <path
                  class="j"
                  d="M197.864,259.368a4.025,4.025,0,0,0,2.293-2.984,3.259,3.259,0,0,0,0-.438,7.816,7.816,0,0,0-4.074-6.652c.038.075,3.9,4.92,1.046,8.151-1.968,2.231-8.731,2.638-11.7.877a5.823,5.823,0,0,0,1.351,1.046A12.261,12.261,0,0,0,197.864,259.368Z"
                  transform="translate(-184.488 -248.331)"
                />
              </g>
              <g transform="translate(0 6.18)">
                <path
                  class="k"
                  d="M190.364,258.224a5.532,5.532,0,0,0-11.06,0c0,.053,0,.1,0,.154s0,.1,0,.154a2.833,2.833,0,0,0,1.614,2.108,8.658,8.658,0,0,0,7.828,0,2.84,2.84,0,0,0,1.618-2.108,2.011,2.011,0,0,0,0-.308Z"
                  transform="translate(-179.299 -252.846)"
                />
                <path
                  class="i"
                  d="M188.7,259.51a2.84,2.84,0,0,0,1.618-2.108,2.011,2.011,0,0,0,0-.308,5.451,5.451,0,0,0-.373-1.836c.137,2.372-.987,3.811-4.482,4.256-3.6.461-5.779-1.035-6.08-1.574a3.229,3.229,0,0,0,1.489,1.57A8.658,8.658,0,0,0,188.7,259.51Z"
                  transform="translate(-179.258 -251.716)"
                />
              </g>
              <g transform="translate(40.66 0.684)">
                <path
                  class="k"
                  d="M217.965,254.441a5.491,5.491,0,0,0-10.977,0c0,.053,0,.1,0,.153s0,.1,0,.154a2.815,2.815,0,0,0,1.6,2.091,8.6,8.6,0,0,0,7.768,0,2.818,2.818,0,0,0,1.607-2.091c0-.053,0-.1,0-.154S217.97,254.494,217.965,254.441Z"
                  transform="translate(-206.984 -249.104)"
                />
                <path
                  class="i"
                  d="M216.317,255.718a2.818,2.818,0,0,0,1.607-2.091c0-.053,0-.1,0-.154s0-.1,0-.153a5.418,5.418,0,0,0-.37-1.823c.135,2.354-.98,3.783-4.449,4.225-3.578.457-5.735-1.028-6.033-1.563a3.2,3.2,0,0,0,1.477,1.558A8.6,8.6,0,0,0,216.317,255.718Z"
                  transform="translate(-206.943 -247.982)"
                />
              </g>
            </g>
          </g>
          <g transform="translate(-157.455 127.46)">
            <g transform="translate(78.409 141.577)">
              <path
                class="d"
                d="M114.3,241.568V411.339c0,2.039-1.347,4.076-4.039,5.629-5.384,3.109-14.115,3.109-19.5,0-2.692-1.554-4.037-3.591-4.037-5.629V241.568Z"
                transform="translate(-86.726 -241.568)"
              />
            </g>
            <path
              class="e"
              d="M34.174,367.215l85.411-206.261h11.9L216.9,367.232a10.915,10.915,0,0,1-7.963,14.882l-1.391.276a10.914,10.914,0,0,0-8.708,12.059h0a10.914,10.914,0,0,1-14.04,11.785l-4.217-1.3a10.916,10.916,0,0,0-13.152,5.925l-4.246,9.364a10.915,10.915,0,0,1-15.508,4.88L131.1,415.279a10.917,10.917,0,0,0-11.132,0L103.4,425.1a10.915,10.915,0,0,1-15.508-4.88l-4.249-9.367a10.914,10.914,0,0,0-13.147-5.925l-4.222,1.3a10.914,10.914,0,0,1-14.037-11.787v-.006a10.914,10.914,0,0,0-8.7-12.062l-1.4-.279A10.915,10.915,0,0,1,34.174,367.215Z"
              transform="translate(-33.338 -137.771)"
            />
            <path
              class="f"
              d="M34.174,367.215l85.411-206.261h11.9L216.9,367.232a10.915,10.915,0,0,1-7.963,14.882l-1.391.276a10.914,10.914,0,0,0-8.708,12.059h0a10.914,10.914,0,0,1-14.04,11.785l-4.217-1.3a10.916,10.916,0,0,0-13.152,5.925l-4.246,9.364a10.915,10.915,0,0,1-15.508,4.88L131.1,415.279a10.917,10.917,0,0,0-11.132,0L103.4,425.1a10.915,10.915,0,0,1-15.508-4.88l-4.249-9.367a10.914,10.914,0,0,0-13.147-5.925l-4.222,1.3a10.914,10.914,0,0,1-14.037-11.787v-.006a10.914,10.914,0,0,0-8.7-12.062l-1.4-.279A10.915,10.915,0,0,1,34.174,367.215Z"
              transform="translate(-33.338 -137.771)"
            />
            <path
              class="g"
              d="M134.985,183.563l-9.542-21.68H113.548L97.77,198.619,46.222,323.1a6.324,6.324,0,0,0,5.372,5.1h0a8.254,8.254,0,0,1,6.862,9.1l-.185,1.583A8.25,8.25,0,0,0,69.4,347.557l4.227-1.608A8.249,8.249,0,0,1,84.1,350.3l5.242,11.76a8.251,8.251,0,0,0,11.765,3.727l14.158-9.435a8.249,8.249,0,0,1,8.458,0l15.806,9.433a8.251,8.251,0,0,0,11.765-3.727l5.24-11.758a8.249,8.249,0,0,1,10.473-4.352l4.225,1.608a8.251,8.251,0,0,0,11.132-8.668l-.185-1.579a8.252,8.252,0,0,1,6.862-9.1,6.254,6.254,0,0,0,4.573-3.046Z"
              transform="translate(-27.299 -137.336)"
            />
            <path
              class="e"
              d="M47.244,309.873l66.369-154.532h11.9l68.018,154.535c2.45,4.97,1.05,10.895-4.418,11.79a8.252,8.252,0,0,0-6.862,9.1l.185,1.579a8.251,8.251,0,0,1-11.132,8.668l-4.225-1.608a8.249,8.249,0,0,0-10.473,4.352l-5.24,11.758a8.251,8.251,0,0,1-11.765,3.727l-15.806-9.433a8.249,8.249,0,0,0-8.458,0l-14.158,9.435a8.251,8.251,0,0,1-11.765-3.727l-5.242-11.76A8.249,8.249,0,0,0,73.7,339.405l-4.227,1.608a8.25,8.25,0,0,1-11.131-8.667l.185-1.583a8.255,8.255,0,0,0-6.862-9.1h0C46.192,320.766,44.794,314.843,47.244,309.873Z"
              transform="translate(-27.365 -140.402)"
            />
            <path
              class="h"
              d="M47.244,309.873l66.369-154.532h11.9l68.018,154.535c2.45,4.97,1.05,10.895-4.418,11.79a8.252,8.252,0,0,0-6.862,9.1l.185,1.579a8.251,8.251,0,0,1-11.132,8.668l-4.225-1.608a8.249,8.249,0,0,0-10.473,4.352l-5.24,11.758a8.251,8.251,0,0,1-11.765,3.727l-15.806-9.433a8.249,8.249,0,0,0-8.458,0l-14.158,9.435a8.251,8.251,0,0,1-11.765-3.727l-5.242-11.76A8.249,8.249,0,0,0,73.7,339.405l-4.227,1.608a8.25,8.25,0,0,1-11.131-8.667l.185-1.583a8.255,8.255,0,0,0-6.862-9.1h0C46.192,320.766,44.794,314.843,47.244,309.873Z"
              transform="translate(-27.365 -140.402)"
            />
            <path
              class="g"
              d="M119.393,155.341H107.5L59.132,267.953a7.5,7.5,0,0,0,1.219.859l.37.207a7.435,7.435,0,0,1,3.772,7.116v0a7.437,7.437,0,0,0,9.3,7.821l2.884-.761a7.436,7.436,0,0,1,8.448,3.678l3.958,7.382a7.436,7.436,0,0,0,10,3.078L110,291.63a7.439,7.439,0,0,1,6.887,0l10.919,5.7a7.435,7.435,0,0,0,10-3.077l3.958-7.382a7.436,7.436,0,0,1,8.448-3.678l2.883.761a7.436,7.436,0,0,0,9.3-7.821,7.435,7.435,0,0,1,3.774-7.116l.369-.207a7.355,7.355,0,0,0,2.041-1.724Z"
              transform="translate(-21.249 -140.402)"
            />
            <path
              class="e"
              d="M58.484,253.861,108.37,149l.391-.63a6.342,6.342,0,0,1,11.016,0l.4.648,49.876,104.849a7.435,7.435,0,0,1-2.691,10.394l-.369.206a7.438,7.438,0,0,0-3.774,7.117,7.434,7.434,0,0,1-9.3,7.819l-2.883-.759a7.435,7.435,0,0,0-8.448,3.677l-3.958,7.381a7.435,7.435,0,0,1-10,3.077l-10.921-5.7a7.436,7.436,0,0,0-6.885,0l-10.921,5.7a7.435,7.435,0,0,1-10-3.077l-3.958-7.381a7.435,7.435,0,0,0-8.448-3.677l-2.884.759a7.435,7.435,0,0,1-9.3-7.819v0a7.434,7.434,0,0,0-3.772-7.116l-.37-.207A7.437,7.437,0,0,1,58.484,253.861Z"
              transform="translate(-22.073 -145.169)"
            />
            <path
              class="g"
              d="M58.484,253.861,108.37,149l.391-.63a6.342,6.342,0,0,1,11.016,0l.4.648,49.876,104.849a7.435,7.435,0,0,1-2.691,10.394l-.369.206a7.438,7.438,0,0,0-3.774,7.117,7.434,7.434,0,0,1-9.3,7.819l-2.883-.759a7.435,7.435,0,0,0-8.448,3.677l-3.958,7.381a7.435,7.435,0,0,1-10,3.077l-10.921-5.7a7.436,7.436,0,0,0-6.885,0l-10.921,5.7a7.435,7.435,0,0,1-10-3.077l-3.958-7.381a7.435,7.435,0,0,0-8.448-3.677l-2.884.759a7.435,7.435,0,0,1-9.3-7.819v0a7.434,7.434,0,0,0-3.772-7.116l-.37-.207A7.437,7.437,0,0,1,58.484,253.861Z"
              transform="translate(-22.073 -145.169)"
            />
            <g transform="translate(68.683 308.443)">
              <g transform="translate(30.16)">
                <path
                  class="i"
                  d="M100.646,363.416a8.466,8.466,0,0,1,16.925,0c.007.081.007.154.007.236s0,.154-.007.236a4.338,4.338,0,0,1-2.47,3.224,13.25,13.25,0,0,1-11.978,0,4.344,4.344,0,0,1-2.476-3.224c-.007-.082-.007-.156-.007-.236S100.639,363.5,100.646,363.416Z"
                  transform="translate(-100.639 -355.186)"
                />
                <path
                  class="j"
                  d="M103.123,366.781a4.344,4.344,0,0,1-2.476-3.224c-.007-.082-.007-.156-.007-.236s0-.156.007-.236a8.45,8.45,0,0,1,4.4-7.189c-.041.081-4.211,5.317-1.131,8.808,2.128,2.411,9.436,2.851,12.645.949a6.3,6.3,0,0,1-1.461,1.129A13.25,13.25,0,0,1,103.123,366.781Z"
                  transform="translate(-100.639 -354.854)"
                />
              </g>
              <g transform="translate(43.848 6.679)">
                <path
                  class="k"
                  d="M109.965,365.545a5.979,5.979,0,0,1,11.953,0c0,.057,0,.11,0,.167s0,.11,0,.166a3.067,3.067,0,0,1-1.745,2.278,9.361,9.361,0,0,1-8.459,0,3.07,3.07,0,0,1-1.749-2.278,1.552,1.552,0,0,1-.006-.166A1.6,1.6,0,0,1,109.965,365.545Z"
                  transform="translate(-109.959 -359.734)"
                />
                <path
                  class="i"
                  d="M111.714,366.935a3.07,3.07,0,0,1-1.749-2.278,1.566,1.566,0,0,1-.006-.166,1.6,1.6,0,0,1,.006-.167,5.9,5.9,0,0,1,.4-1.984c-.147,2.563,1.068,4.118,4.845,4.6,3.895.5,6.246-1.119,6.571-1.7a3.49,3.49,0,0,1-1.61,1.7A9.361,9.361,0,0,1,111.714,366.935Z"
                  transform="translate(-109.959 -358.513)"
                />
              </g>
              <g transform="translate(0 0.74)">
                <path
                  class="k"
                  d="M80.109,361.457a5.933,5.933,0,0,1,11.861,0,2.346,2.346,0,0,1,0,.332,3.037,3.037,0,0,1-1.73,2.259,9.281,9.281,0,0,1-8.395,0,3.047,3.047,0,0,1-1.736-2.259,2.346,2.346,0,0,1,0-.332Z"
                  transform="translate(-80.103 -355.69)"
                />
                <path
                  class="i"
                  d="M81.845,362.836a3.043,3.043,0,0,1-1.736-2.259,2.346,2.346,0,0,1,0-.332,5.861,5.861,0,0,1,.4-1.969c-.147,2.544,1.059,4.089,4.807,4.566,3.865.493,6.2-1.11,6.521-1.689a3.453,3.453,0,0,1-1.6,1.683A9.281,9.281,0,0,1,81.845,362.836Z"
                  transform="translate(-80.103 -354.478)"
                />
              </g>
            </g>
          </g>
          <g transform="translate(257.011 289.738)">
            <g transform="translate(15.877)">
              <path
                class="e"
                d="M228.922,396.454l8.6-4.916s4.125-22.736,1.04-42.281c-4.338-27.48-23.049-36.959-29.843-34.227s-8.393,10.872,3.879,26.75C219.842,351.147,229.155,370.95,228.922,396.454Z"
                transform="translate(-203.484 -314.579)"
              />
              <path
                class="f"
                d="M228.922,396.454l8.6-4.916s4.125-22.736,1.04-42.281c-4.338-27.48-23.049-36.959-29.843-34.227s-8.393,10.872,3.879,26.75C219.842,351.147,229.155,370.95,228.922,396.454Z"
                transform="translate(-203.484 -314.579)"
              />
              <path
                class="l"
                d="M229.057,384.283a.789.789,0,0,1-.085-.006.615.615,0,0,1-.524-.692c3.556-25.727-6.79-53.913-18.414-65.272a.613.613,0,1,1,.858-.877c11.836,11.566,22.379,40.21,18.771,66.316A.613.613,0,0,1,229.057,384.283Z"
                transform="translate(-200.501 -313.322)"
              />
            </g>
            <g transform="translate(0 23.598)">
              <path
                class="e"
                d="M210.122,402.711c-1.36.78-4.161-.927-5.234-1.646a12.955,12.955,0,0,1-4.313-4.952,12.147,12.147,0,0,1-1.081-7.3,28.784,28.784,0,0,1,.924-3.882l1.084-3.769a9.576,9.576,0,0,0,.552-3.8c-.33-2.576-2.617-4.383-4.7-5.939-2.3-1.723-4.694-3.995-4.681-7.1a10.919,10.919,0,0,1,2.733-6.5c1.483-1.88,3.244-3.539,4.585-5.522a7.619,7.619,0,0,0,1.561-4.822,7.93,7.93,0,0,0-1.05-3.012c-1.1-2.019-2.541-3.87-3.3-6.041s-.648-4.87,1.047-6.422c1.389-1.275,3.456-1.453,5.339-1.328a12.248,12.248,0,0,1,4.966,1.748c4.924,2.739,7.737,8.573,12.964,10.68,2.015.812,4.224.99,6.326,1.547s4.244,1.629,5.218,3.572c1.918,3.832-1.642,8.555-.294,12.623,1.194,3.6,4.692,4.977,6.656,8.223a7.7,7.7,0,0,1,.919,4.713,22.616,22.616,0,0,1-1.987,7.963,56.744,56.744,0,0,1-4.371,7.174C233.907,389,210.329,402.591,210.122,402.711Z"
                transform="translate(-192.674 -330.647)"
              />
              <path
                class="m"
                d="M210.122,402.711c-1.36.78-4.161-.927-5.234-1.646a12.955,12.955,0,0,1-4.313-4.952,12.147,12.147,0,0,1-1.081-7.3,28.784,28.784,0,0,1,.924-3.882l1.084-3.769a9.576,9.576,0,0,0,.552-3.8c-.33-2.576-2.617-4.383-4.7-5.939-2.3-1.723-4.694-3.995-4.681-7.1a10.919,10.919,0,0,1,2.733-6.5c1.483-1.88,3.244-3.539,4.585-5.522a7.619,7.619,0,0,0,1.561-4.822,7.93,7.93,0,0,0-1.05-3.012c-1.1-2.019-2.541-3.87-3.3-6.041s-.648-4.87,1.047-6.422c1.389-1.275,3.456-1.453,5.339-1.328a12.248,12.248,0,0,1,4.966,1.748c4.924,2.739,7.737,8.573,12.964,10.68,2.015.812,4.224.99,6.326,1.547s4.244,1.629,5.218,3.572c1.918,3.832-1.642,8.555-.294,12.623,1.194,3.6,4.692,4.977,6.656,8.223a7.7,7.7,0,0,1,.919,4.713,22.616,22.616,0,0,1-1.987,7.963,56.744,56.744,0,0,1-4.371,7.174C233.907,389,210.329,402.591,210.122,402.711Z"
                transform="translate(-192.674 -330.647)"
              />
              <g transform="translate(9.323 3.881)">
                <path
                  class="l"
                  d="M220.32,394.032H220.3a.617.617,0,0,1-.6-.639c1.013-24.35-10.46-51.233-18.8-59.043a.614.614,0,0,1,.839-.9c8.511,7.967,20.219,35.3,19.192,59.989A.615.615,0,0,1,220.32,394.032Z"
                  transform="translate(-198.231 -333.29)"
                />
                <path
                  class="l"
                  d="M217.463,356.371a.6.6,0,0,1-.182-.028,82.389,82.389,0,0,0-17.671-2.849.613.613,0,0,1-.587-.637.6.6,0,0,1,.639-.589,83.923,83.923,0,0,1,17.982,2.9.614.614,0,0,1-.181,1.2Z"
                  transform="translate(-199.022 -324.396)"
                />
              </g>
            </g>
          </g>
        </g>
        <g transform="translate(1246.234 612.608)">
          <g transform="translate(0 0)">
            <path
              class="i"
              d="M443.1,127.11a2.789,2.789,0,0,0-1.62-2.209,8.693,8.693,0,0,0-7.871,0,2.792,2.792,0,0,0-1.62,2.209h-.01v278.8c0,1.74,2.489,3.15,5.56,3.15s5.56-1.41,5.56-3.15h.01V127.11Z"
              transform="translate(-374.735 -123.96)"
            />
            <ellipse
              class="k"
              cx="5.561"
              cy="3.151"
              rx="5.561"
              ry="3.151"
              transform="translate(57.242 0)"
            />
            <g transform="translate(36.71 48.591)">
              <path
                class="e"
                d="M506.986,217.823,496.07,198.916l-72.581-41.871L418,160.208v25.21l72.581,41.871,5.6-3.089Z"
                transform="translate(-417.996 -157.045)"
              />
              <g transform="translate(0 3.164)">
                <path
                  class="e"
                  d="M418,159.2l72.581,41.871,10.916,18.907-10.916,6.3L418,184.409Z"
                  transform="translate(-417.997 -159.199)"
                />
              </g>
              <g class="n" transform="translate(0 3.164)">
                <path
                  d="M418,159.2l72.581,41.871,10.949,18.964-10.949,6.246L418,184.409Z"
                  transform="translate(-417.997 -159.199)"
                />
              </g>
              <path
                class="g"
                d="M496.07,198.916l-5.492,3.164L418,160.208l5.492-3.164Z"
                transform="translate(-417.996 -157.045)"
              />
              <path
                class="h"
                d="M467.417,188.718l5.492-3.164,10.916,18.907-5.46,3.22Z"
                transform="translate(-394.835 -143.683)"
              />
            </g>
            <g transform="translate(18.338 0)">
              <g transform="translate(0 3.204)">
                <path
                  class="e"
                  d="M488.985,168.08,416.4,126.141l-10.916,6.3L416.4,151.351l72.581,41.939Z"
                  transform="translate(-405.487 -126.141)"
                />
              </g>
              <g transform="translate(10.916)">
                <path
                  class="e"
                  d="M412.92,127.164l5.335-3.2,72.688,42L485.5,169.1Z"
                  transform="translate(-412.92 -123.96)"
                />
              </g>
              <g class="o" transform="translate(10.916)">
                <path
                  class="l"
                  d="M412.92,127.164l5.335-3.2,72.688,42L485.5,169.1Z"
                  transform="translate(-412.92 -123.96)"
                />
              </g>
              <path
                class="e"
                d="M467.782,177.767l-5.442,3.142V155.7l5.442-3.142Z"
                transform="translate(-378.843 -110.557)"
              />
              <path
                class="g"
                d="M467.782,177.767l-5.442,3.142V155.7l5.442-3.142Z"
                transform="translate(-378.843 -110.557)"
              />
            </g>
            <g transform="translate(0 77.898)">
              <g transform="translate(0 3.204)">
                <path
                  class="e"
                  d="M476.5,221.12l-72.581-41.939L393,185.484l10.916,18.907L476.5,246.329Z"
                  transform="translate(-393.001 -179.181)"
                />
              </g>
              <g transform="translate(10.916)">
                <path
                  class="e"
                  d="M400.434,180.2l5.335-3.2,72.688,42-5.442,3.142Z"
                  transform="translate(-400.434 -177)"
                />
              </g>
              <g class="o" transform="translate(10.916)">
                <path
                  class="l"
                  d="M400.434,180.2l5.335-3.2,72.688,42-5.442,3.142Z"
                  transform="translate(-400.434 -177)"
                />
              </g>
              <path
                class="e"
                d="M455.3,230.807l-5.442,3.142V208.74L455.3,205.6Z"
                transform="translate(-366.357 -163.597)"
              />
              <path
                class="g"
                d="M455.3,230.807l-5.442,3.142V208.74L455.3,205.6Z"
                transform="translate(-366.357 -163.597)"
              />
            </g>
          </g>
        </g>
        <g transform="translate(1112.686 546.529)">
          <g transform="translate(0)">
            <path
              class="e"
              d="M317.616,80.352a27.248,27.248,0,0,1,7.631-1.37,20.879,20.879,0,0,1,7.307.99,16.522,16.522,0,0,1,6.079,3.559,14.607,14.607,0,0,1,3.948,6.339,14.853,14.853,0,0,1,.833,5.864,17.746,17.746,0,0,1-1.156,5.064A28.937,28.937,0,0,1,340,105.34q-1.32,2.161-2.492,4.09a23.947,23.947,0,0,0-1.908,3.857,6.85,6.85,0,0,0-.4,3.754,3.266,3.266,0,0,1-.217,1.748,1.911,1.911,0,0,1-1.223,1.112l-6.055,2a2.306,2.306,0,0,1-1.764-.125,1.859,1.859,0,0,1-1.1-1.314,14.627,14.627,0,0,1-.489-5.892,20.49,20.49,0,0,1,1.394-5.142,29.509,29.509,0,0,1,2.39-4.543q1.37-2.132,2.506-4.053a19.111,19.111,0,0,0,1.707-3.654,5.289,5.289,0,0,0,.024-3.41,6.336,6.336,0,0,0-4.193-4.137,11.438,11.438,0,0,0-7.417.285Q314,92.147,313.052,99.972a4.74,4.74,0,0,1-.4,1.589,2.142,2.142,0,0,1-1.332.925l-6.452,2.131a1.856,1.856,0,0,1-1.392-.07,1.746,1.746,0,0,1-.938-1.1,12.965,12.965,0,0,1-.167-6.261,20.5,20.5,0,0,1,2.644-6.7,24.874,24.874,0,0,1,5.178-5.994A21.873,21.873,0,0,1,317.616,80.352Zm18.128,44.2a2.3,2.3,0,0,1,2.86,1.442L340.917,133a2.291,2.291,0,0,1-1.438,2.861l-6.85,2.263a2.294,2.294,0,0,1-2.864-1.441l-2.315-7.011a2.289,2.289,0,0,1,1.442-2.861Z"
              transform="translate(-302.069 -78.967)"
            />
            <g transform="translate(55.588 64.196)">
              <path
                class="e"
                d="M348.4,124.787a18.719,18.719,0,0,1,5.042-1.8,14.427,14.427,0,0,1,5.086-.156,11.425,11.425,0,0,1,4.541,1.737,10.071,10.071,0,0,1,3.407,3.868,10.313,10.313,0,0,1,1.231,3.9,12.3,12.3,0,0,1-.214,3.581,19.964,19.964,0,0,1-1.021,3.349q-.654,1.619-1.234,3.067a16.364,16.364,0,0,0-.862,2.843,4.7,4.7,0,0,0,.154,2.6,2.261,2.261,0,0,1,.05,1.216,1.327,1.327,0,0,1-.706.9l-3.9,2.046a1.6,1.6,0,0,1-1.216.116,1.283,1.283,0,0,1-.894-.773,10.083,10.083,0,0,1-1-3.957,13.994,13.994,0,0,1,.367-3.66,20.238,20.238,0,0,1,1.112-3.363q.694-1.606,1.247-3.045a13.221,13.221,0,0,0,.75-2.68,3.667,3.667,0,0,0-.37-2.326,4.378,4.378,0,0,0-3.325-2.343,7.884,7.884,0,0,0-5.017,1.032q-4.36,2.289-4.121,7.725a3.26,3.26,0,0,1-.094,1.128,1.466,1.466,0,0,1-.8.78l-4.15,2.182a1.288,1.288,0,0,1-.959.11,1.2,1.2,0,0,1-.764-.646,8.931,8.931,0,0,1-.821-4.244,14.06,14.06,0,0,1,1.041-4.864,17.127,17.127,0,0,1,2.846-4.669A15.092,15.092,0,0,1,348.4,124.787Zm17.343,28.043a1.582,1.582,0,0,1,2.112.658l2.37,4.513a1.582,1.582,0,0,1-.656,2.11l-4.41,2.316a1.579,1.579,0,0,1-2.11-.656l-2.37-4.513a1.584,1.584,0,0,1,.656-2.112Z"
                transform="translate(-339.918 -122.678)"
              />
              <g class="p">
                <path
                  d="M348.4,124.787a18.719,18.719,0,0,1,5.042-1.8,14.427,14.427,0,0,1,5.086-.156,11.425,11.425,0,0,1,4.541,1.737,10.071,10.071,0,0,1,3.407,3.868,10.313,10.313,0,0,1,1.231,3.9,12.3,12.3,0,0,1-.214,3.581,19.964,19.964,0,0,1-1.021,3.349q-.654,1.619-1.234,3.067a16.364,16.364,0,0,0-.862,2.843,4.7,4.7,0,0,0,.154,2.6,2.261,2.261,0,0,1,.05,1.216,1.327,1.327,0,0,1-.706.9l-3.9,2.046a1.6,1.6,0,0,1-1.216.116,1.283,1.283,0,0,1-.894-.773,10.083,10.083,0,0,1-1-3.957,13.994,13.994,0,0,1,.367-3.66,20.238,20.238,0,0,1,1.112-3.363q.694-1.606,1.247-3.045a13.221,13.221,0,0,0,.75-2.68,3.667,3.667,0,0,0-.37-2.326,4.378,4.378,0,0,0-3.325-2.343,7.884,7.884,0,0,0-5.017,1.032q-4.36,2.289-4.121,7.725a3.26,3.26,0,0,1-.094,1.128,1.466,1.466,0,0,1-.8.78l-4.15,2.182a1.288,1.288,0,0,1-.959.11,1.2,1.2,0,0,1-.764-.646,8.931,8.931,0,0,1-.821-4.244,14.06,14.06,0,0,1,1.041-4.864,17.127,17.127,0,0,1,2.846-4.669A15.092,15.092,0,0,1,348.4,124.787Zm17.343,28.043a1.582,1.582,0,0,1,2.112.658l2.37,4.513a1.582,1.582,0,0,1-.656,2.11l-4.41,2.316a1.579,1.579,0,0,1-2.11-.656l-2.37-4.513a1.584,1.584,0,0,1,.656-2.112Z"
                  transform="translate(-339.918 -122.678)"
                />
              </g>
            </g>
            <g transform="translate(84.534 7.099)">
              <path
                class="e"
                d="M371.924,122.7a1.875,1.875,0,0,1,1.231,2.315l-1.762,5.775a1.878,1.878,0,0,1-2.313,1.231l-5.644-1.723a1.873,1.873,0,0,1-1.231-2.313l1.764-5.776a1.875,1.875,0,0,1,2.312-1.229Zm8.758-38.076a22.18,22.18,0,0,1,5.742,2.685,17.072,17.072,0,0,1,4.413,4.109,13.539,13.539,0,0,1,2.379,5.248,11.93,11.93,0,0,1-.354,6.1,12.165,12.165,0,0,1-2.212,4.311,14.512,14.512,0,0,1-3.162,2.837,23.839,23.839,0,0,1-3.651,1.971q-1.9.82-3.6,1.557a19.364,19.364,0,0,0-3.093,1.674,5.6,5.6,0,0,0-2.037,2.32,2.679,2.679,0,0,1-.972,1.066,1.56,1.56,0,0,1-1.339.163l-4.989-1.523a1.889,1.889,0,0,1-1.119-.915,1.517,1.517,0,0,1-.112-1.4,11.952,11.952,0,0,1,2.456-4.167,16.842,16.842,0,0,1,3.359-2.777,24.172,24.172,0,0,1,3.739-1.906q1.921-.775,3.586-1.524a15.557,15.557,0,0,0,2.867-1.636,4.34,4.34,0,0,0,1.624-2.266,5.179,5.179,0,0,0-.847-4.742,9.349,9.349,0,0,0-5.086-3.31q-5.58-1.7-9.909,3.072a3.874,3.874,0,0,1-1.018.874,1.75,1.75,0,0,1-1.325-.01l-5.317-1.623a1.514,1.514,0,0,1-.9-.7,1.43,1.43,0,0,1-.106-1.181,10.608,10.608,0,0,1,2.843-4.262,16.734,16.734,0,0,1,4.933-3.23,20.246,20.246,0,0,1,6.286-1.56A17.9,17.9,0,0,1,380.682,84.621Z"
                transform="translate(-359.627 -83.801)"
              />
              <g class="q">
                <path
                  class="l"
                  d="M371.924,122.7a1.875,1.875,0,0,1,1.231,2.315l-1.762,5.775a1.878,1.878,0,0,1-2.313,1.231l-5.644-1.723a1.873,1.873,0,0,1-1.231-2.313l1.764-5.776a1.875,1.875,0,0,1,2.312-1.229Zm8.758-38.076a22.18,22.18,0,0,1,5.742,2.685,17.072,17.072,0,0,1,4.413,4.109,13.539,13.539,0,0,1,2.379,5.248,11.93,11.93,0,0,1-.354,6.1,12.165,12.165,0,0,1-2.212,4.311,14.512,14.512,0,0,1-3.162,2.837,23.839,23.839,0,0,1-3.651,1.971q-1.9.82-3.6,1.557a19.364,19.364,0,0,0-3.093,1.674,5.6,5.6,0,0,0-2.037,2.32,2.679,2.679,0,0,1-.972,1.066,1.56,1.56,0,0,1-1.339.163l-4.989-1.523a1.889,1.889,0,0,1-1.119-.915,1.517,1.517,0,0,1-.112-1.4,11.952,11.952,0,0,1,2.456-4.167,16.842,16.842,0,0,1,3.359-2.777,24.172,24.172,0,0,1,3.739-1.906q1.921-.775,3.586-1.524a15.557,15.557,0,0,0,2.867-1.636,4.34,4.34,0,0,0,1.624-2.266,5.179,5.179,0,0,0-.847-4.742,9.349,9.349,0,0,0-5.086-3.31q-5.58-1.7-9.909,3.072a3.874,3.874,0,0,1-1.018.874,1.75,1.75,0,0,1-1.325-.01l-5.317-1.623a1.514,1.514,0,0,1-.9-.7,1.43,1.43,0,0,1-.106-1.181,10.608,10.608,0,0,1,2.843-4.262,16.734,16.734,0,0,1,4.933-3.23,20.246,20.246,0,0,1,6.286-1.56A17.9,17.9,0,0,1,380.682,84.621Z"
                  transform="translate(-359.627 -83.801)"
                />
              </g>
            </g>
          </g>
        </g>
        <g transform="translate(1073.96 633.313)">
          <g transform="translate(0 0)">
            <g transform="translate(20.803 137.366)">
              <path
                class="k"
                d="M323.352,348.64c.156,2.645.445,5.029-.637,7.913-1.555,4.14-4.706,5.986-6.565,7.594a31.426,31.426,0,0,0-5.452,6.343,27.072,27.072,0,0,1-5.693,6.831c-1.765,1.47-6.652,1.505-8.987.469s-3.108-1.939-2.887-5.7c.216-3.672,11.649-4.811,11.649-4.811Z"
                transform="translate(-288.351 -176.732)"
              />
              <path
                class="e"
                d="M294.857,362.362c-.981.348-1.263,2.2-1.485,5.878-.167,2.786-1.422,6.844,2.384,8.678,3.069,1.48,8.039.637,10.185-1.949,2.677-3.228,4.5-6.08,8.31-10.007,3.3-3.406,6.694-4.509,8.27-11.419,1.012-4.441.323-6.3-2.652-7.644-4.092-1.842-10.692,3.776-13.945,4.616C303.123,351.239,295.837,362.013,294.857,362.362Z"
                transform="translate(-288.376 -178.189)"
              />
              <path
                class="h"
                d="M294.857,362.362c-.981.348-1.263,2.2-1.485,5.878-.167,2.786-1.422,6.844,2.384,8.678,3.069,1.48,8.039.637,10.185-1.949,2.677-3.228,4.5-6.08,8.31-10.007,3.3-3.406,6.694-4.509,8.27-11.419,1.012-4.441.323-6.3-2.652-7.644-4.092-1.842-10.692,3.776-13.945,4.616C303.123,351.239,295.837,362.013,294.857,362.362Z"
                transform="translate(-288.376 -178.189)"
              />
              <path
                class="r"
                d="M302.33,348.981c.173-.044.361-.109.554-.179a10.638,10.638,0,0,1,8.818,6.039c.527,1.226-1.19,2.137-2.18,1.172-2.213-4.629-6.929-5.741-8.812-6A4.738,4.738,0,0,1,302.33,348.981Z"
                transform="translate(-284.782 -176.656)"
              />
              <path
                class="r"
                d="M300.392,350.318a10.562,10.562,0,0,1,8.186,5.977c.526,1.225-1.191,2.137-2.181,1.17a10.883,10.883,0,0,0-7.336-5.71C299.516,351.243,299.963,350.753,300.392,350.318Z"
                transform="translate(-285.555 -175.945)"
              />
              <g transform="translate(36.538 208.888)">
                <path
                  class="k"
                  d="M363.9,389.85c.568,3.136-.595,4.742-4.672,6.989s-10.419,2.917-17.032.847-9.736-5.036-12.488-5.784-9.59-.943-12.307-1.8-2.647-2.256-2.566-5.541Z"
                  transform="translate(-314.711 -368.783)"
                />
                <path
                  class="e"
                  d="M317.866,373.819s-1.378,3.908-2.146,7.333c-.611,2.724-1.5,7.28-.583,9.355s4.626,3.479,10.419,3.551c3.751.047,9.546,2.711,13.5,5.026,4.087,2.389,8.573,2.488,12.955,2.062,5.165-.5,10.564-3.382,11.439-5.5s1.125-3.714-10.917-9.132c-.11-.05-13.987-5.488-19.733-12.09Z"
                  transform="translate(-314.744 -373.819)"
                />
                <g class="p">
                  <path
                    d="M317.866,373.819s-1.378,3.908-2.146,7.333c-.611,2.724-1.5,7.28-.583,9.355s4.626,3.479,10.419,3.551c3.751.047,9.546,2.711,13.5,5.026,4.087,2.389,8.573,2.488,12.955,2.062,5.165-.5,10.564-3.382,11.439-5.5s1.125-3.714-10.917-9.132c-.11-.05-13.987-5.488-19.733-12.09Z"
                    transform="translate(-314.744 -373.819)"
                  />
                </g>
                <path
                  class="r"
                  d="M332.418,396s1.25-10.407,9.787-13.219c7.715-2.542,14.293,3.271,13.449,8.191C355.085,394.284,345.607,401.638,332.418,396Z"
                  transform="translate(-306.461 -369.907)"
                />
                <path
                  class="r"
                  d="M341.037,381.707a9.877,9.877,0,0,0-5.836,2.454c-2.486,2.008-2.585,3.372-2.585,3.372a1.284,1.284,0,0,1-2.188-1.1s.674-5.248,7.616-6.186A2.821,2.821,0,0,1,341.037,381.707Z"
                  transform="translate(-307.399 -370.827)"
                />
                <path
                  class="r"
                  d="M337.768,380.061a8.675,8.675,0,0,0-5.888,2.2c-2.485,2.008-2.532,3.623-2.532,3.623a1.285,1.285,0,0,1-2.19-1.1s.674-5.249,7.618-6.186A2.819,2.819,0,0,1,337.768,380.061Z"
                  transform="translate(-308.931 -371.599)"
                />
                <path
                  class="r"
                  d="M334.632,378.182a8.672,8.672,0,0,0-6.024,1.8c-2.617,1.833-2.774,3.441-2.774,3.441a1.285,1.285,0,0,1-2.109-1.248s1.031-5.19,8.022-5.651A2.822,2.822,0,0,1,334.632,378.182Z"
                  transform="translate(-310.548 -372.561)"
                />
              </g>
              <g transform="translate(6.459 178.212)">
                <path
                  class="s"
                  d="M294.4,352.932l-.141,7.073s4.631,3.708,11.425.539c.207-.433.919-7.367.919-7.367Z"
                  transform="translate(-294.263 -352.932)"
                />
              </g>
              <g transform="translate(39.651 203.997)">
                <path
                  class="s"
                  d="M316.97,370.489s-.05,3.357-.106,5.275,2.153,4.822,8.022,4.731c4.123-.065,7.022-1.115,6.926-4.51l.1-4.891Z"
                  transform="translate(-316.863 -370.489)"
                />
              </g>
              <path
                class="k"
                d="M290.04,349.622c.681-14.914,3.882-21.4,3.882-21.4s-.773-18.451-2.8-41.064c-3.086-34.427-.358-39.529,6.729-54.847,0,0,57.082-6.223,58.365,17.843,1.6,29.969.636,90.845-.37,101.4s-.8.787-3.8,22.134c-2.37,16.885-7.486,62.5-7.486,62.5-7.757,3.995-14.941-.605-14.941-.605s-3.606-56.015-2.864-68.2c.9-14.883,1.824-18.656,1.824-18.656s-5.794-48.354-6.684-56.138c0,0-1.22,13.874-2.226,24.43s-1.11,19.072-3.776,40.9c-2.091,17.116-7.066,54.061-7.066,54.061a14.2,14.2,0,0,1-12.592-1.182S289.489,361.687,290.04,349.622Z"
                transform="translate(-289.865 -231.589)"
              />
              <path
                class="r"
                d="M315.566,358.846s-.159-8.267-11.3-9.637l-.646.35a9.634,9.634,0,0,1,5.576-4.13c3.732-.927,6.643.091,8.54,2.641S317.525,357.275,315.566,358.846Z"
                transform="translate(-283.419 -178.395)"
              />
              <path
                class="i"
                d="M317.538,279.1l-.726-9.881a80.839,80.839,0,0,1-17.64-8.856,23.729,23.729,0,0,0,14.208,10.777l1.78,9.7.151,22.692Z"
                transform="translate(-285.503 -218.102)"
              />
            </g>
            <g transform="translate(0 4.413)">
              <path
                class="s"
                d="M308.034,141.062c-3.117,3.9-26.255,41.15-29.636,48.129s-4.193,11.494,1.154,17.133c8.034,8.477,28.281,28.916,28.281,28.916l11.338-27.056-21.028-13.2,20.591-43.518Z"
                transform="translate(-275.701 -141.062)"
              />
              <path
                class="t"
                d="M294.35,178.641s-5.756-2.022-10.557,1.093c0,0,3.789-5.35,11.621-3.343Z"
                transform="translate(-271.908 -124.716)"
              />
              <path
                class="k"
                d="M316.582,188.288c-.031,0-18.639-9.232-18.639-9.232-6.862-.983-15.8,9.749-15.537,17.968l16.307,14.387Z"
                transform="translate(-272.561 -123.285)"
              />
            </g>
            <g transform="translate(21.205 55.629)">
              <path
                class="s"
                d="M308.549,175.935l-1.171,12.933h0c-2.911.908-2.4,5.008-.683,7.536l-.344,3.805,19.031,1.72,2.855-21.852Z"
                transform="translate(-283.034 -175.935)"
              />
              <path
                class="i"
                d="M294.7,194.268c2.113-5.753,10.466-10.168,15.527-9.77,1.953.153,4.253.367,4.253.367-1.178,2.354.523,4.03,3.6,4.916,3.974,1.143,11.273,1.8,15.577-.75l.132-1.09s8.117,2.309,10.51,3.209c6.724,2.533,8.944,3.827,15.916,21.918,2.981,7.735,1.141,15.757-6.887,20.134-.6,4.778-1.275,12.31-1.275,12.31a273.551,273.551,0,0,1,4.863,31.093s-4.233,9.6-33.528,7.987c-22.691-1.251-33.252-12.732-33.252-12.732.56-5.572,4.081-14.463,11.3-31.237C292.9,216.513,291.568,202.806,294.7,194.268Z"
                transform="translate(-290.139 -171.933)"
              />
            </g>
            <g transform="translate(72.316 56.697)">
              <g transform="translate(41.914)">
                <g transform="translate(29.949)">
                  <path
                    class="u"
                    d="M376.6,186a.205.205,0,0,1-.069-.021,4.936,4.936,0,0,1,2.792-9.289.193.193,0,1,1-.041.385,4.549,4.549,0,0,0-2.572,8.561.194.194,0,0,1-.11.364Z"
                    transform="translate(-373.871 -176.662)"
                  />
                </g>
                <g transform="translate(29.192 4.303)">
                  <path
                    class="k"
                    d="M373.691,180.61l1.113,1.376a5.7,5.7,0,0,0,2.083,2.576L378,185.938l.824-.665-.006-.007a1.635,1.635,0,0,0,.555-1.729,5.353,5.353,0,0,0-3.046-3.769,1.636,1.636,0,0,0-1.806.184l-.006-.009Z"
                    transform="translate(-373.198 -179.592)"
                  />
                  <path
                    class="j"
                    d="M376.438,185.8c1.442.555,2.417-.232,2.177-1.759a5.36,5.36,0,0,0-3.046-3.769c-1.442-.554-2.416.234-2.177,1.759A5.36,5.36,0,0,0,376.438,185.8Z"
                    transform="translate(-373.355 -179.356)"
                  />
                </g>
                <g transform="translate(28.561 6.179)">
                  <path
                    class="i"
                    d="M372.926,182.179,376,185.984l1.309-1.057,0-.006a1.166,1.166,0,0,0,.4-1.234A3.826,3.826,0,0,0,375.528,181a1.166,1.166,0,0,0-1.289.131l0-.006Z"
                    transform="translate(-372.926 -180.869)"
                  />
                </g>
                <path
                  class="e"
                  d="M353.667,194.324l1.488,4.71.006.018.012.035h0c.749,2.307,3.015,4.13,6.63,4.929,7.273,1.607,17.245-1.546,22.272-7.041,2.5-2.73,3.3-5.525,2.594-7.844h0l-.012-.035-.006-.018-1.489-4.71-1.867.94a12.409,12.409,0,0,0-3.287-1.193c-7.273-1.607-17.245,1.545-22.272,7.041A12.458,12.458,0,0,0,355.747,194Z"
                  transform="translate(-353.391 -173.366)"
                />
                <path
                  class="f"
                  d="M353.667,194.324l1.488,4.71.006.018.012.035h0c.749,2.307,3.015,4.13,6.63,4.929,7.273,1.607,17.245-1.546,22.272-7.041,2.5-2.73,3.3-5.525,2.594-7.844h0l-.012-.035-.006-.018-1.489-4.71-1.867.94a12.409,12.409,0,0,0-3.287-1.193c-7.273-1.607-17.245,1.545-22.272,7.041A12.458,12.458,0,0,0,355.747,194Z"
                  transform="translate(-353.391 -173.366)"
                />
                <g transform="translate(32.507 0.026)">
                  <path
                    class="u"
                    d="M377.474,186.5a4.928,4.928,0,0,1-1.758-.527.2.2,0,0,1-.082-.261.193.193,0,0,1,.261-.082,4.548,4.548,0,1,0,2.572-8.561.193.193,0,1,1,.041-.385,4.935,4.935,0,1,1-1.034,9.816Z"
                    transform="translate(-375.612 -176.68)"
                  />
                </g>
                <path
                  class="e"
                  d="M378.593,180.871c-7.273-1.607-17.245,1.545-22.272,7.041s-3.208,11.253,4.065,12.86,17.245-1.545,22.272-7.041S385.867,182.478,378.593,180.871Z"
                  transform="translate(-353.479 -174.886)"
                />
                <path
                  class="h"
                  d="M378.593,180.871c-7.273-1.607-17.245,1.545-22.272,7.041s-3.208,11.253,4.065,12.86,17.245-1.545,22.272-7.041S385.867,182.478,378.593,180.871Z"
                  transform="translate(-353.479 -174.886)"
                />
                <path
                  class="e"
                  d="M357.653,189.05c4.261-4.656,13.067-7.439,19.229-6.079a7.563,7.563,0,0,1,3.457,1.582,2.678,2.678,0,0,1,.89,2.517,7.5,7.5,0,0,1-1.952,3.482c-4.258,4.659-13.064,7.442-19.226,6.082a7.586,7.586,0,0,1-3.457-1.582c-.05-.046-.1-.09-.138-.135C354.513,192.935,356.632,190.166,357.653,189.05Z"
                  transform="translate(-352.455 -173.867)"
                />
                <path
                  class="v"
                  d="M357.653,189.05c4.261-4.656,13.067-7.439,19.229-6.079a7.563,7.563,0,0,1,3.457,1.582,2.678,2.678,0,0,1,.89,2.517,7.5,7.5,0,0,1-1.952,3.482c-4.258,4.659-13.064,7.442-19.226,6.082a7.586,7.586,0,0,1-3.457-1.582c-.05-.046-.1-.09-.138-.135C354.513,192.935,356.632,190.166,357.653,189.05Z"
                  transform="translate(-352.455 -173.867)"
                />
                <path
                  class="w"
                  d="M358.153,190.671c4.259-4.657,13.065-7.44,19.226-6.077a7.547,7.547,0,0,1,3.459,1.582c.049.044.094.088.138.135a7.5,7.5,0,0,1-1.952,3.482c-4.258,4.659-13.064,7.442-19.226,6.082a7.586,7.586,0,0,1-3.457-1.582c-.05-.046-.1-.09-.138-.135A7.477,7.477,0,0,1,358.153,190.671Z"
                  transform="translate(-352.202 -173.108)"
                />
                <g transform="translate(9.879 13.909)">
                  <path
                    class="k"
                    d="M364.571,188.334l-4.243,3.171c-.335.248.075.495.52.311l5.644-2.332Z"
                    transform="translate(-360.205 -185.101)"
                  />
                  <path
                    class="k"
                    d="M366.125,189.677l4.244-3.169c.333-.25-.076-.495-.52-.311l-5.646,2.331Z"
                    transform="translate(-358.332 -186.132)"
                  />
                  <path
                    class="e"
                    d="M367.042,190.029a4.669,4.669,0,0,1-4.133.275c-.968-.58-.687-1.642.627-2.373a4.67,4.67,0,0,1,4.133-.276C368.637,188.234,368.355,189.3,367.042,190.029Z"
                    transform="translate(-359.208 -185.59)"
                  />
                  <path
                    class="i"
                    d="M366.084,189.465a3.013,3.013,0,0,1-2.666.178c-.624-.375-.444-1.059.4-1.532a3.011,3.011,0,0,1,2.667-.176C367.114,188.307,366.932,188.993,366.084,189.465Z"
                    transform="translate(-358.874 -185.399)"
                  />
                </g>
              </g>
              <path
                class="s"
                d="M400.283,187.291a9.261,9.261,0,0,0-2.648-2.817l1.226,3.877c.953,2.857-1.128,6.618-3.2,8.752-1.4,1.438-4.011,3.153-4.225,2.619-.3-.743.137-4.413-.615-7.713-.9-3.97-3.259-2.04-3.871.941-.335,1.629-2.1,5.773-2.605,8.929-.614,3.864.191,7.173-.972,9.969-.908,2.182-10.347,13.968-15.775,20.273-5.64-7.518-13.287-17.311-19.844-25.721-9.232-11.837-11.137-14.319-18.537-14.563-5.87,16.953,4.195,34.023,4.195,34.023s13.88,16.872,22.534,25.74c8.352,8.559,11.288,7.832,15.295,4.419S385.58,237.3,390.008,228.8c4.441-8.528,9.117-14.939,11.743-19.444C406.172,201.763,404.861,193.815,400.283,187.291Z"
                transform="translate(-323.798 -173.001)"
              />
              <g transform="translate(0 18.032)">
                <path
                  class="k"
                  d="M327.992,189.057s6.936-.985,11.613,2.763a60.019,60.019,0,0,1,7.979,8.808c6.477,8.661,18.554,24.769,18.554,24.769s-4.939,10.695-18.89,14.7c0,0-11.861-12.407-16.3-18.561C323.076,210.636,323.883,195.1,327.992,189.057Z"
                  transform="translate(-324.94 -188.94)"
                />
              </g>
            </g>
            <g transform="translate(25.231 67.937)">
              <path
                class="e"
                d="M313.2,184.941s-9.49,4.2-11.41,10.281L292.88,194.1s3.794-6.954,11.328-9.16A16.438,16.438,0,0,1,313.2,184.941Z"
                transform="translate(-292.88 -184.315)"
              />
              <path
                class="h"
                d="M313.2,184.941s-9.49,4.2-11.41,10.281L292.88,194.1s3.794-6.954,11.328-9.16A16.438,16.438,0,0,1,313.2,184.941Z"
                transform="translate(-292.88 -184.315)"
              />
            </g>
            <g transform="translate(56.917 71.829)">
              <path
                class="e"
                d="M323.68,202.658s3.7-11.191,9.226-12.675a20.84,20.84,0,0,0-9.944-3.018,26.436,26.436,0,0,0-8.507,10.427Z"
                transform="translate(-314.455 -186.965)"
              />
              <path
                class="h"
                d="M323.68,202.658s3.7-11.191,9.226-12.675a20.84,20.84,0,0,0-9.944-3.018,26.436,26.436,0,0,0-8.507,10.427Z"
                transform="translate(-314.455 -186.965)"
              />
            </g>
            <g transform="translate(6.899 74.84)">
              <path
                class="e"
                d="M304.038,189.02c-5.3-.068-15.712,6.616-17.556,10.746-1.933,4.333-9.145,58.61-4.613,64.066,5.688,6.85,31.8,22.349,44.016,21.918,10.075-.355,19.971-7.991,21.5-10.69,2.5-4.424-.877-64.68-4.093-69.745C338.075,197.107,310.789,189.107,304.038,189.02Z"
                transform="translate(-280.398 -189.014)"
              />
              <path
                class="h"
                d="M323.558,196.688l-5.233,3.531c-5.261,3.037-8.114,11.044-8.022,30.213s-.288,45.132,2.782,47.867c2.978,2.652,9.257.872,9.627.764,5.509-2.345,9.68-5.872,10.654-7.6,2.5-4.424-.877-64.681-4.093-69.747A17.887,17.887,0,0,0,323.558,196.688Z"
                transform="translate(-266.383 -185.42)"
              />
              <path
                class="g"
                d="M345.591,277.109c-3.6,3.349-11.6,8.357-19.709,8.649-12.2.426-38.332-15.082-44.016-21.926-4.523-5.449,2.674-59.733,4.613-64.066,1.852-4.127,12.263-10.809,17.551-10.751,5.317.073,24.026,5.377,33.543,11.268l-5.228,3.525c-5.258,3.04-8.122,11.044-8.019,30.224s-.294,45.133,2.776,47.864c2.981,2.644,9.253.867,9.62.765A32.406,32.406,0,0,0,345.591,277.109Z"
                transform="translate(-280.397 -189.016)"
              />
              <path
                class="e"
                d="M302.929,189.02c-5.817,0-14.6,4.726-17.555,9.308s-5.057,17.913-2.89,21.832c2.131,3.855,16.588,11.705,26.42,15.718,7.856,3.206,11.965,2.372,14.747.389,1.768-1.26,2.077-5.023,2.21-7.812.341-7.171-.248-11.008,4.078-15.217s12.027-7.759,13.335-4.585c0,0,1.742-4.776-7.2-9.382C321.26,191.636,310.089,189.021,302.929,189.02Z"
                transform="translate(-279.837 -189.014)"
              />
              <g transform="translate(30.399 34.114)">
                <path
                  class="k"
                  d="M302.035,214.721l5.187,1.972s-.68,15.817-.664,22c0,0-1.209,2.666-2.341,3.1s-2.61-2.547-2.857-4.782S301.485,220.079,302.035,214.721Z"
                  transform="translate(-301.008 -211.083)"
                />
                <path
                  class="i"
                  d="M301.973,217.108l5.277,1.828a.9.9,0,0,0,1.25-.817l.2-2.554a1.4,1.4,0,0,0-.868-1.345l-5.3-1.909a.875.875,0,0,0-1.232.8l-.2,2.666A1.381,1.381,0,0,0,301.973,217.108Z"
                  transform="translate(-301.096 -212.244)"
                />
                <path
                  class="u"
                  d="M303.516,228.14a2.55,2.55,0,0,1,1.448,2.2,1.025,1.025,0,0,1-1.448,1.075,2.488,2.488,0,0,1-1.448-2.2C302.068,228.3,302.717,227.816,303.516,228.14Z"
                  transform="translate(-300.64 -204.841)"
                />
              </g>
              <g transform="translate(7.883 22.772)">
                <path
                  class="k"
                  d="M287.6,206.994l5.079,2.238s-1.5,15.76-1.8,21.942c0,0-1.345,2.6-2.5,2.977s-2.475-2.679-2.605-4.924S286.774,212.316,287.6,206.994Z"
                  transform="translate(-285.765 -203.362)"
                />
                <path
                  class="i"
                  d="M287.128,209.37l5.176,2.1a.9.9,0,0,0,1.291-.752l.335-2.539a1.4,1.4,0,0,0-.8-1.389l-5.2-2.18a.876.876,0,0,0-1.273.736L286.325,208A1.382,1.382,0,0,0,287.128,209.37Z"
                  transform="translate(-285.507 -204.521)"
                />
                <path
                  class="u"
                  d="M288.06,220.469a2.551,2.551,0,0,1,1.332,2.275,1.025,1.025,0,0,1-1.5,1,2.484,2.484,0,0,1-1.331-2.275C286.606,220.551,287.279,220.1,288.06,220.469Z"
                  transform="translate(-285.394 -197.107)"
                />
              </g>
              <path
                class="e"
                d="M283.223,228.371c-.051,6.33-.179,8.943.11,11.986.2,2.083.586,3.932,3.655,6.118s16.293,9.142,20.209,11c4.789,2.272,10,3.782,9.921.075-.072-3.237-.007-10.148,0-12.5.007-2.911-1.4-4.8-7.336-7.549-5.629-2.6-18.157-9.646-21.63-11.231C285.216,224.927,283.238,226.477,283.223,228.371Z"
                transform="translate(-279.108 -171.794)"
              />
            </g>
            <g transform="translate(25.151 4.067)">
              <g transform="translate(1.994)">
                <path
                  class="j"
                  d="M345.812,167.235s10-12.9,2.572-19.88c-8.8-8.267-20.485-8.092-35.741-2.773-7.145,2.492-17.53,9.727-18.446,23.747-.706,10.814,29.453,18.79,28.764,26.2Z"
                  transform="translate(-294.184 -140.827)"
                />
              </g>
              <g transform="translate(56.194 32.029)">
                <path
                  class="t"
                  d="M331.088,162.635s4.092,6.563,3.517,7.23-3.306,1.32-3.306,1.32Z"
                  transform="translate(-331.088 -162.635)"
                />
              </g>
              <g transform="translate(14.787 9.303)">
                <path
                  class="s"
                  d="M331.819,149.105c4.089,2.253,11.147,6.4,12.579,21.727,1.469,15.731-2.169,21.084-4.362,23.083-1.48,1.347-8.938,1.649-12.789.339-4.819-1.639-15.205-7.126-19.922-15.35-5.549-9.677-6.406-22.4.429-27.192C317.373,144.964,328.359,147.2,331.819,149.105Z"
                  transform="translate(-302.894 -147.161)"
                />
              </g>
              <path
                class="t"
                d="M320.821,180.571c3.41.6,7.935.413,9.985-.363a18.407,18.407,0,0,1-9.843-2.817Z"
                transform="translate(-279.706 -123.691)"
              />
              <path
                class="j"
                d="M322.751,169.634a15.581,15.581,0,0,0,9.981-12.569c1.544-9.98-10.922-14.2-10.922-14.2Z"
                transform="translate(-279.242 -139.874)"
              />
              <path
                class="j"
                d="M335.015,182.777c-.821,1.3-4.04,2.532-7.434-.984-3.494-3.617-3.279-9.937,1.594-11.419,4.842-1.472,7.226,4.178,7.226,4.178,4.573-8.924,1.345-23.324,1.345-23.324s-10.629-10.944-27.874-5.717c-9.27,2.811-14.034,12.482-15.052,19.69s-3.8,20.093-.214,24.772c3.663,4.775,17.5,11.89,29.987,11.29,12.512-.6,12.512-3.071,12.512-3.071l-1.921-15.763S335.126,182.563,335.015,182.777Z"
                transform="translate(-292.826 -139.294)"
              />
            </g>
            <path
              class="s"
              d="M298.445,142.47c4.855-5.9,12.539-4.509,18.507-3.532s14.742,1.827,13.18,5.8-5.935,7.68-16.176,6.688c-10.225-.988-12.175,0-14.469,2.72C299.486,154.147,292.758,151.427,298.445,142.47Z"
              transform="translate(-266.111 -138.057)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'NotFound',
}
</script>

<style scoped>
svg {
  width: 100%;
}
.a {
  fill: #e1f0ff;
  opacity: 0.438;
}
.b {
  opacity: 0.106;
}
.c {
  opacity: 0.05;
}
.d {
  fill: #b16668;
}
.e {
  fill: #27debf;
}
.f,
.n {
  opacity: 0.3;
}
.g {
  opacity: 0.1;
}
.h,
.p {
  opacity: 0.2;
}
.i {
  fill: #37474f;
}
.j {
  fill: #263238;
}
.k {
  fill: #455a64;
}
.l {
  fill: #fff;
}
.m {
  opacity: 0.15;
}
.o {
  opacity: 0.4;
}
.q {
  opacity: 0.6;
}
.r {
  fill: #fafafa;
}
.s {
  fill: #ffa8a7;
}
.t {
  fill: #f28f8f;
}
.u {
  fill: #e0e0e0;
}
.v {
  opacity: 0.5;
}
.w {
  fill: #f0f0f0;
}
</style>
