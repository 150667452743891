<template>
  <svg
    width="65"
    height="64"
    viewBox="0 0 65 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.3666 30.1336C25.2999 29.0669 23.6999 29.0669 22.6333 30.1336C21.5666 31.2002 21.5666 32.8002 22.6333 33.8669L30.6333 41.8669C31.1666 42.4002 31.6999 42.6669 32.4999 42.6669C33.2999 42.6669 33.8333 42.4002 34.3666 41.8669L53.0333 20.5336C53.8333 19.2002 53.8333 17.6002 52.4999 16.8002C51.4333 16.0002 49.8333 16.0002 49.0333 17.0669L32.4999 36.0002L26.3666 30.1336Z"
      fill="#02BC7D"
    />
    <path
      d="M56.4999 29.3335C54.8999 29.3335 53.8332 30.4002 53.8332 32.0002C53.8332 43.7335 44.2332 53.3335 32.4999 53.3335C20.7666 53.3335 11.1666 43.7335 11.1666 32.0002C11.1666 26.4002 13.2999 21.0668 17.2999 17.0668C21.2999 12.8002 26.6333 10.6668 32.4999 10.6668C34.0999 10.6668 35.9666 10.9335 37.5666 11.2002C38.8999 11.7335 40.4999 10.9335 41.0332 9.3335C41.5666 7.7335 40.4999 6.66683 39.1666 6.1335H38.8999C36.7666 5.60016 34.6333 5.3335 32.4999 5.3335C17.8333 5.3335 5.83325 17.3335 5.83325 32.2668C5.83325 39.2002 8.76659 46.1335 13.5666 50.9335C18.6333 56.0002 25.2999 58.6668 32.2332 58.6668C46.8999 58.6668 58.8999 46.6668 58.8999 32.0002C59.1666 30.4002 57.8332 29.3335 56.4999 29.3335Z"
      fill="#02BC7D"
    />
  </svg>
</template>

<script>
export default {
  name: 'Check',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
