<template>
  <v-progress-circular
    v-if="isMobileOrIPad"
    class="centerize"
    indeterminate
    color="primary"
    :size="50"
  />
  <Lottie
    v-else
    :options="defaultOptions"
    :height="400"
    style="max-width: 600px"
    renderer="html"
    @animCreated="handleAnimation"
  />
</template>
<script>
import Lottie from 'vue-lottie'
import animationData from '@/assets/images/pc.json'
import DeviceMixin from 'components/mixins/deviceMixin'

export default {
  name: 'DvrLoader',
  components: {
    Lottie,
  },
  mixins: [DeviceMixin],
  data() {
    return {
      defaultOptions: {
        animationData,
      },
      anim: null,
    }
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim
      this.anim.setSpeed(1.25)
    },
  },
}
</script>
<style scoped>
.centerize {
  position: absolute;
  padding: 6px;
  top: 25%;
  transform: translate(-50%, -50%);
  left: 50%;
}
</style>
