import { render, staticRenderFns } from "./review-form.vue?vue&type=template&id=04d0c504&scoped=true"
import script from "./review-form.vue?vue&type=script&lang=js"
export * from "./review-form.vue?vue&type=script&lang=js"
import style0 from "./review-form.vue?vue&type=style&index=0&id=04d0c504&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04d0c504",
  null
  
)

export default component.exports