<template>
  <v-container>
    <v-row class="align-center flex-column">
      <v-col cols="auto">
        <div class="text-h4">Empty Place</div>
      </v-col>
      <v-col cols="auto">
        <div class="text-subtitle-1">Unfortunately, nothing could be found</div>
      </v-col>
      <v-col cols="auto">
        <v-btn color="primary" large @click="backHome"> Back Home </v-btn>
      </v-col>
      <v-col cols="6">
        <img width="100%" src="@/assets/images/not-found.png" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NotFound',
  methods: {
    backHome() {
      this.$router.push({ name: 'tourist-trips' })
      this.$store.commit('hideNotFound')
    },
  },
}
</script>

<style scoped></style>
