import axios from 'axios'
import { sum, values } from 'lodash'
import imgCompressor from '@/utils/imgCompressor'

const uploadPreset = 'tcu2ksyf'
const uploadUrl = 'https://api.cloudinary.com/v1_1/do4tedxg6/upload'
const uploadHeaders = { 'Content-Type': 'multipart/form-data' }

export async function uploadToCloudinary(
  files,
  { onUploadingChange, onUploadProgress } = {},
  isMobile = false,
  compress = true
) {
  onUploadingChange = onUploadingChange || (() => undefined)
  onUploadProgress = onUploadProgress || (() => undefined)

  const filesProgress = {}
  let totalSize = 0

  onUploadingChange(true)
  onUploadProgress(0)

  const promises = files.map(async (f, index) => {
    const file =
      f.type.includes('image') && compress ? await imgCompressor(f) : f
    totalSize += file.size
    filesProgress[index] = 0

    const formData = new FormData()
    formData.append('upload_preset', uploadPreset)
    formData.append('file', file)

    return axios.post(uploadUrl, formData, {
      headers: uploadHeaders,
      withCredentials: false,
      onUploadProgress: progressEvent => {
        filesProgress[index] = progressEvent.loaded
        const progress = Math.min(
          Math.round(sum(values(filesProgress)) * 100) / totalSize,
          100
        )
        onUploadProgress(progress)
      },
    })
  })
  if (isMobile) {
    console.log(files)
  }
  return Promise.all(promises)
    .then(result => result.map(r => r.data.secure_url))
    .catch(error => {
      throw error
    })
    .finally(() => onUploadingChange(false))
}
