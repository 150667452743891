<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="14"
    viewBox="0 0 19 14"
  >
    <g transform="translate(-4 -7.5)">
      <path class="a" d="M4.5,18h18" transform="translate(0 -3.5)" />
      <path class="a" d="M4.5,9h18" />
      <path class="a" d="M4.5,27h18" transform="translate(0 -7)" />
      <circle
        class="b"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(6.5 7.5)"
      />
      <circle
        class="b"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(12 13)"
      />
      <circle
        class="b"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(17.5 18.5)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Filters',
}
</script>

<style scoped>
.a {
  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.b {
  fill: currentColor;
}
</style>
