<template>
  <v-stepper-content :step="step" class="pa-0">
    <v-container fluid>
      <div class="text-h6 mb-2 font-weight-medium">
        {{ $t('Special Requests') }}
      </div>
      <v-row
        v-if="specialRequest.length"
        class="align-center align-md-start flex-column"
      >
        <v-col
          v-for="(request, idx) in specialRequest"
          :key="idx"
          cols="12"
          md="4"
        >
          <AddRemoveItem
            v-if="request.type === 'counter'"
            :label="$t(request.key)"
            :value.sync="request.amount"
            :max="request.max"
          />
          <div v-else class="d-flex justify-space-around">
            <div class="flex-grow-1">{{ $t(request.key) }}</div>
            <v-radio-group
              v-model="request.amount"
              class="font-weight-medium mt-0 pt-0"
              style="margin-right: -15px"
              hide-details
              row
            >
              <v-radio :label="$t('Yes')" :value="1" />
              <v-radio :label="$t('No')" :value="0" />
            </v-radio-group>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="text-caption red--text font-weight-medium"
        >
          <div v-for="err in errors" :key="err">
            {{ err }}
          </div>
        </v-col>
      </v-row>
      <span v-else class="grey--text">
        {{ $t('No Special requests available') }}
      </span>
      <div
        v-if="
          checkinInfo.offer_parking ||
          (isDesignedVr &&
            (preCheckIn.has_assigned_parking ||
              preCheckIn.has_parking_pass ||
              preCheckIn.listing_num_of_rooms >= 3))
        "
      >
        <v-divider class="my-4" />
        <div class="text-h6 font-weight-medium">
          {{ $t('Do you need parking place?') }}
        </div>
        <v-radio-group v-model="clientInfo.need_parking" row>
          <v-radio :label="$t('Yes')" :value="true" />
          <v-radio :label="$t('No')" :value="false" />
        </v-radio-group>
        <span
          v-if="preCheckIn.has_parking_pass && clientInfo.need_parking"
          class="warning--text my-2"
        >
          A parking pass will be available for purchase from our store.
        </span>
        <div v-if="clientInfo.need_parking">
          <v-form
            v-if="
              preCheckIn.has_assigned_parking || preCheckIn.has_parking_pass
            "
            ref="form"
            class="flex d-flex"
          >
            <v-row no-gutters>
              <v-col cols="12" class="grey--text pb-3">
                If you don't have the details yet you would be able to update
                this form again on your arrival date
              </v-col>
              <v-col cols="12" sm="6" md="3" class="pr-3">
                <v-text-field
                  v-model="clientInfo.car_model"
                  outlined
                  :label="$t('CarModel')"
                  prepend-inner-icon="directions_car"
                />
              </v-col>
              <v-col cols="12" sm="6" md="3" class="pr-3">
                <v-text-field
                  v-model="clientInfo.licence_plate"
                  outlined
                  :label="$t('LicencePlate')"
                  prepend-inner-icon="straighten"
                />
              </v-col>
            </v-row>
          </v-form>
          <AddRemoveItem
            v-else
            :label="$t('How many cars?')"
            :value.sync="clientInfo.car_amount"
            :min="1"
            :max="parkingSpaces"
            :description="carAmountWarn"
          />
        </div>
      </div>
    </v-container>
  </v-stepper-content>
</template>

<script>
import AddRemoveItem from '@/components/common/AddRemoveItem'
import formRules from '@/components/mixins/form-rules-mixin'
import GlobalMixin from 'components/mixins/global-mixin'
import { isNumber } from 'lodash/fp'

export default {
  name: 'SpecialRequests',
  components: { AddRemoveItem },
  mixins: [formRules, GlobalMixin],
  props: ['preCheckIn', 'step'],
  data() {
    return {
      specialRequest: [],
      need_parking: false,
      clientInfo: this.preCheckIn.client_info,
      checkinInfo: this.preCheckIn.checkin_info || {},
      errors: [],
    }
  },
  computed: {
    allowedRequests() {
      return this.specialRequest.filter(req => this.isAllowedReq(req.key))
    },
    parkingSpaces() {
      return Number(this.preCheckIn.parking_spaces) || 3
    },
    carAmountWarn() {
      if (this.preCheckIn.parking_spaces) {
        return ''
      }
      if (
        this.clientInfo.car_amount &&
        this.clientInfo.car_amount > this.preCheckIn.listing_num_of_rooms - 2
      ) {
        return `This listing is best suited for ${
          this.preCheckIn.listing_num_of_rooms - 2
        } cars, please advise with us through the booking channel if that is an issues.`
      }
      return ''
    },
    isDesignedVr() {
      return this.companyNameConf === 'DesignedVR'
    },
  },
  mounted() {
    const { special_requests_settings, special_requests } = this.preCheckIn
    const cleanKeysMap = Object.keys(special_requests).reduce((map, key) => {
      const cleanKey = key.split(' X ')[1] || key.split(' X ')[0]
      map[cleanKey] = key
      return map
    }, {})

    this.specialRequest = (special_requests_settings || []).map(r => {
      const requestKey = cleanKeysMap[r.name]
      let amount = null
      const firstChar = requestKey && requestKey.charAt(0)
      if ((requestKey && r.type === 'toggle') || r.type === 'counter')
        amount = 0
      if (requestKey && parseInt(firstChar)) amount = parseInt(firstChar)

      return { amount, key: r.name, ...r }
    })
  },
  methods: {
    validate() {
      this.errors = []
      const toggled = []
      this.specialRequest.forEach(r => {
        const isMissing =
          r.required && r.type === 'toggle' && !isNumber(r.amount)

        if (isMissing) this.errors.push(`Please select an option for ${r.name}`)
        toggled.push(!!isMissing)
      })
      if (
        toggled.some(r => r) ||
        (this.clientInfo.need_parking &&
          (this.preCheckIn.has_assigned_parking ||
            this.preCheckIn.has_parking_pass) &&
          !this.$refs.form.validate())
      ) {
        return
      }
      const special_requests = this.specialRequest.reduce(
        (requests, { key, amount }) => {
          if (amount >= 1) {
            requests[`${Number(amount)} X ${key}`] = true
            return requests
          } else {
            requests[key] = false
          }
          return requests
        },
        {}
      )
      this.$emit('step-forward', { ...this.clientInfo, special_requests })
    },
    isAllowedReq(reqName) {
      if (!this.preCheckIn.allow_air_mattress && reqName.includes('Air')) {
        return false
      }
      if (reqName.includes('Toys')) {
        return false
      }
      return !reqName.includes('Long stay')
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .v-input--radio-group__input {
  flex-wrap: nowrap !important;
}
</style>
