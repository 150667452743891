<template>
  <v-stepper-content :step="step" class="pa-0 fill-height">
    <div class="px-3">
      <div class="d-flex justify-space-between align-center">
        <div class="text-h6 darkpurle--text font-weight-medium">
          {{ $t('Store') }}
        </div>
        <v-btn elevation="0" large color="primary" @click="nextStep">
          {{ $t('NotNow') }}
        </v-btn>
      </div>

      <div class="text-subtitle-1 darkpurle--text font-weight-medium pt-2">
        {{ $t('EnjoyableStay') }}
      </div>
    </div>
    <TouristStoreView />
  </v-stepper-content>
</template>

<script>
import TouristStoreView from 'components/store/tourist-store-view'
export default {
  name: 'StoreStep',
  components: { TouristStoreView },
  props: ['step'],
  methods: {
    nextStep() {
      this.$emit('step-forward')
    },
  },
}
</script>

<style scoped></style>
