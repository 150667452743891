<template>
  <v-container elevation="0" class="store-container">
    <v-sheet elevation="2" class="pa-3 rounded">
      <ReservationCard
        v-if="reservation"
        :reservation="reservation"
        :listing="reservation.listing"
      />
    </v-sheet>
    <v-row>
      <v-col cols="12" sm="4" lg="3">
        <store-items-filters
          :items="itemTopics"
          :selected.sync="selectedTopics"
        />
      </v-col>
      <v-col cols="12" sm="8" lg="9">
        <store-items-list :items="filteredItems" :currency="currency" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ReservationCard from 'components/common/reservation-card'
import featureFlagsMixin from '@/components/mixins/featureFlagsMixin'
import StoreItemsList from '@/components/store/store-items-list'
import StoreItemsFilters from 'components/store/store-items-filters'
import uniq from 'lodash/fp/uniq'
export default {
  name: 'TouristStoreView',
  components: {
    StoreItemsFilters,
    StoreItemsList,
    ReservationCard,
  },
  mixins: [featureFlagsMixin],
  data() {
    return {
      searchTerm: '',
      selectedTopics: [],
    }
  },
  computed: {
    ...mapGetters({
      reservationItems: 'reservationItems',
      reservation: 'currentReservation',
    }),
    items() {
      if (this.searchTerm) {
        return this.reservationItems.filter(i =>
          i.name.includes(this.searchTerm)
        )
      }
      return this.reservationItems
    },
    itemTopics() {
      return uniq(this.reservationItems.map(({ item }) => item.topic_name))
    },
    filteredItems() {
      if (this.selectedTopics && this.selectedTopics.length > 0) {
        return this.items.filter(t => {
          return this.selectedTopics.includes(t.item.topic_name)
        })
      }
      return this.items
    },
    currency() {
      return this.reservation && this.reservation.listing.get_currency
    },
  },
  mounted() {
    this.getReservationStoreItems(this.$route.params.id)
    this.getReservationBasicInfo(this.$route.params.id)
  },
  methods: {
    ...mapActions(['getReservationStoreItems', 'getReservationBasicInfo']),
  },
}
</script>

<style scoped>
@media (min-width: 1904px) {
  .store-container {
    max-width: 1400px;
  }
}
</style>
