export default {
  state: {
    not_found: false,
  },
  mutations: {
    showNotFound(state) {
      state.not_found = true
    },
    hideNotFound(state) {
      state.not_found = false
    },
  },
  getters: {
    isNotFound(state) {
      return state.not_found
    },
  },
}
