import Vue from 'vue'
import Vuex from 'vuex'
// import get from 'lodash/fp/get'
import TouristModule from './TouristModule'
import userModule from './userModule'
import CartModule from './CartModule'
import FeatureFlagsModule from './featureFlagsModule'
import GlobalModule from './GlobalModule'

Vue.use(Vuex)
// const errorStatus = get('response.status')

export const store = new Vuex.Store({
  modules: {
    tourist: TouristModule,
    cart: CartModule,
    user: userModule,
    featureFlags: FeatureFlagsModule,
    global: GlobalModule,
  },
})
