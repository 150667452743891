import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueI18n from 'vue-i18n'
import router from './router'
import { sync } from 'vuex-router-sync'
import { store } from '@/store'
import * as VueGoogleMaps from 'vue2-google-maps'
import axios from 'axios'
import { Datetime } from 'vue-datetime'
import GlobalMixin from 'components/mixins/global-mixin'
import CountryFlag from 'vue-country-flag'
import VuePhoneNumberInput from 'vue-phone-number-input'
import VueSignaturePad from 'vue-signature-pad'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

Vue.component('vue-phone-number-input', VuePhoneNumberInput)

Vue.component('country-flag', CountryFlag)

// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'

// if (
//   process.env.NODE_ENV === 'production' ||
//   process.env.NODE_ENV === 'staging'
// ) {
//   axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT
// }
axios.defaults.withCredentials = true
sync(store, router)
Vue.use(VueI18n)
Vue.config.productionTip = false
Vue.use(Datetime)
Vue.use(VueSignaturePad)
Vue.component('datetime', Datetime)
Vue.mixin(GlobalMixin)
const i18n = new VueI18n({
  locale: 'en', // set locale
  messages: loadLocaleMessages(), // set locale messages
})
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCzvYaJgsS0jhNhEjzREy_G9Quie3zc7A4',
  },
})
new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}
