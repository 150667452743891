export default {
  data() {
    return {
      nameRules: [
        v => !!v || 'Name is required',
        v => v.length <= 30 || 'Name must be less than 30 characters',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      phoneRules: [
        v => !!v || 'Phone is required',
        v => /^[+0-9\s\-()]+$/.test(v) || 'Phone number must be valid',
      ],
      zipCodeRules: [
        v => !!v || 'Zip code is required',
        v => /^[0-9-a-zA-Z]+$/.test(v) || 'Zip code be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 8) || 'Password must be at least 8 characters',
        v =>
          (v && /[a-z]/.test(v)) ||
          'Password must contain at least one lowercase letter',
        v =>
          (v && /[A-Z]/.test(v)) ||
          'Password must contain at least one uppercase letter',
        v =>
          (v && /[0-9]/.test(v)) || 'Password must contain at least one digit',
        v =>
          (v && /[!@#$%^&*]/.test(v)) ||
          'Password must contain at least one special character (!@#$%^&*)',
      ],
      rangeRules: [
        v => !!v || 'Choose start and end dates',
        v => {
          if (v) {
            const dates = v.split(' - ')
            return (!!dates[0] && !!dates[1]) || 'Choose start and end dates'
          }
          return 'Choose start and end dates'
        },
      ],
      required: v => !!v || 'Required field',
      min(min) {
        return value => (value || '') >= min || `Minimun is ${min}`
      },
      max(max) {
        return value => (value || '') <= max || `Maximun is ${max}`
      },
      minCurrentYear: v =>
        (v && v >= new Date().getFullYear()) || 'Invalid year',
    }
  },
}
