<template>
  <div>
    <v-btn
      dark
      small
      class="white--text font-weight-bold ma-2"
      depressed
      rounded
      color="deep-purple lighten-1"
      @click="dialog = true"
    >
      <v-icon small class="me-2">mdi-map</v-icon>
      {{ $t('Travel Guide') }}
    </v-btn>
    <v-dialog v-model="dialog" :fullscreen="isMobile" height="80vh">
      <v-card height="90vh">
        <v-card-title class="d-flex justify-end py-5 px-3">
          <v-icon class="close-button" @click="dialog = false">close</v-icon>
        </v-card-title>
        <v-card-text>
          <iframe
            :src="travelGuideUrl"
            class="travel-guide-iframe"
            frameborder="0"
          ></iframe>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import deviceMixin from 'components/mixins/deviceMixin'

export default {
  mixins: [deviceMixin],
  props: ['travelGuideUrl'],
  data() {
    return {
      dialog: false,
    }
  },
}
</script>
<style scoped>
.travel-guide-iframe {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
}
</style>
