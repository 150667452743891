<template>
  <v-main v-if="trackedListingTask">
    <div>
      <v-app-bar color="white" fixed app hide-on-scroll>
        <img class="ma-4" width="100" :src="listing.public_logo" />
      </v-app-bar>
    </div>
    <v-row no-gutters class="justify-center grey lighten-5">
      <v-col cols="11" md="8" lg="6">
        <h2 class="text-capitalize text-h6 my-6">
          {{ $t('TicketNumber') }}: #{{ trackedListingTask.id }}
        </h2>
        <reservation-box :reservation="reservation" :listing="listing" />

        <v-card elevation="2" class="my-2">
          <v-card-text>
            <div>
              <div class="font-weight-medium text-subtitle-1 mr-3">
                {{ $t('MainIssues') }}
              </div>
              <ul>
                <li
                  v-for="(tag, ind) in trackedListingTask.review.ai_bullets"
                  :key="ind"
                  class="ma-1"
                >
                  {{ tag }}
                </li>
              </ul>
            </div>
            <v-timeline dense class="mt-4">
              <v-timeline-item
                color="green lighten-5"
                fill-dot
                icon="done"
                icon-color="green darken-1"
              >
                <p class="font-weight-medium mb-0">
                  {{ $t('IssueWasCreated') }}
                </p>
                <p class="text-caption secondary--text mb-0">
                  {{
                    formatDate(
                      new Date(trackedListingTask.created_at),
                      'YYYY-MM-DD HH:mm a'
                    )
                  }}
                </p>
              </v-timeline-item>
              <v-timeline-item
                color="green lighten-5"
                fill-dot
                icon="done"
                icon-color="green darken-1"
              >
                <p class="font-weight-medium mb-0">{{ $t('AddMedia') }}:</p>
                <gallery
                  :thumbnails="true"
                  :cols="isMobile ? 4 : isIPad ? 4 : 3"
                  :uploadable="true"
                  :on-change="addImages"
                  :images="trackedListingTask.desc_images"
                />
              </v-timeline-item>

              <v-timeline-item
                :color="isAssigned ? 'green lighten-5' : 'grey lighten-3'"
                :icon="isAssigned ? 'done' : 'clear'"
                :icon-color="isAssigned ? 'green darken-1' : 'white'"
                fill-dot
              >
                <p class="font-weight-medium mb-0">
                  {{ $t('AssignedAgent') }}:
                </p>
                <p
                  v-if="isAssigned"
                  class="text-caption secondary--text mb-0 text-capitalize"
                >
                  <span
                    v-if="
                      trackedListingTask.assigned_contractor.first_name ||
                      trackedListingTask.assigned_contractor.last_name
                    "
                  >
                    {{ trackedListingTask.assigned_contractor.first_name }}
                    {{ trackedListingTask.assigned_contractor.last_name }}
                  </span>
                  <span v-else>
                    {{ trackedListingTask.assigned_contractor.email }}
                  </span>
                  was assigned
                </p>
                <p v-else class="text-caption secondary--text mb-0">
                  {{ $t('NotAssignedYet') }}
                </p>
              </v-timeline-item>

              <v-timeline-item
                :color="
                  trackedListingTask.status !== 'New'
                    ? 'green lighten-5'
                    : 'grey lighten-3'
                "
                :icon="trackedListingTask.status !== 'New' ? 'done' : 'clear'"
                :icon-color="
                  trackedListingTask.status !== 'New'
                    ? 'green darken-1'
                    : 'white'
                "
                fill-dot
              >
                <p class="font-weight-medium mb-0">{{ $t('InProgress') }}</p>
                <p
                  v-if="trackedListingTask.status !== 'New'"
                  class="text-caption secondary--text mb-0"
                >
                  Moved to in progress on
                  {{
                    formatDate(
                      trackedListingTask.scheduled_at,
                      'YYYY-MM-DD HH:mm a'
                    )
                  }}
                </p>
              </v-timeline-item>
              <v-timeline-item
                :color="taskDone ? 'green lighten-5' : 'grey lighten-3'"
                :icon="
                  taskDone
                    ? 'done'
                    : trackedListingTask.status === 'Rejected'
                    ? 'alarm'
                    : 'clear'
                "
                :icon-color="taskDone ? 'green darken-1' : 'white'"
                fill-dot
              >
                <p class="font-weight-medium mb-0">{{ $t('TaskCompleted') }}</p>
                <p v-if="taskDone" class="text-caption secondary--text mb-0">
                  Task was completed on
                  {{
                    formatDate(
                      trackedListingTask.moved_to_done_at,
                      'YYYY-MM-DD HH:mm a'
                    )
                  }}
                </p>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import CommonFunctions from 'components/mixins/common-functions'

import Gallery from 'components/common/gallery.vue'
import DeviceMixin from 'components/mixins/deviceMixin'
import ReservationBox from 'components/reservations/reservation-box.vue'
export default {
  name: 'ListingTaskTracker',
  components: { ReservationBox, Gallery },
  mixins: [CommonFunctions, DeviceMixin],
  computed: {
    reservation() {
      return this.trackedListingTask.review.reservation
    },
    listing() {
      return this.trackedListingTask.listing
    },
    trackedListingTask() {
      return this.$store.state.tourist.trackedListingTask
    },
    taskDone() {
      return (
        this.trackedListingTask.status === 'Done' ||
        this.trackedListingTask.status === 'Closed'
      )
    },
    isAssigned() {
      return (
        this.trackedListingTask &&
        this.trackedListingTask.assigned_contractor_id
      )
    },
  },
  created() {
    this.$store.dispatch(
      'getTrackedListingTask',
      this.$router.currentRoute.params.id
    )
  },
  methods: {
    addImages(images) {
      this.$store.dispatch('addPhotosToTicket', {
        id: this.$router.currentRoute.params.id,
        images,
      })
    },
  },
}
</script>
<style lang="scss"></style>
