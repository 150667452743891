<template>
  <div>
    <v-card elevation="0" :class="[{ 'secondary-lighten1': isMobile }]">
      <v-card-title class="text-h6"> {{ $t('Summary') }} </v-card-title>
      <v-card-text class="black--text">
        <div class="d-flex my-2 justify-space-between">
          <div class="text-subtitle-1">
            {{ item.item_name }}
          </div>
          <div>{{ toDollar(item.amount) }}</div>
        </div>
        <div v-if="tax" class="d-flex text-body-1 mt-5 justify-space-between">
          <div>{{ $t('Tax') }}({{ storeTax }}%)</div>
          <div>{{ toDollar(tax) }}</div>
        </div>
        <v-divider class="my-5 secondary" />
        <div class="py-3">
          <div
            class="d-flex text-subtitle-1 font-weight-medium justify-space-between"
          >
            <div>{{ $t('Total') }}</div>
            <div>{{ toDollar(total) }}</div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import deviceMixin from 'components/mixins/deviceMixin'
import CommonFunctions from 'components/mixins/common-functions'
export default {
  name: 'CheckoutSummary',
  mixins: [deviceMixin, CommonFunctions],
  props: ['item', 'tax', 'total'],
  computed: {
    totalAmount() {
      return this.item.amount.toFixed(2)
    },
  },
}
</script>

<style scoped>
.secondary-lighten1 {
  background-color: var(--v-secondary-lighten1);
}
.theme--light.v-divider {
  border-color: var(--v-secondary-base) !important;
}
</style>
