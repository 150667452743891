<template>
  <div>
    <div class="text-caption secondary--text mb-2">
      {{ title }}
    </div>
    <div class="text-body-2 text-sm-body-1">
      {{ formatDate(date, 'ddd, MMM Do') }}
    </div>
    <div class="text-h6 text-sm-h5 font-weight-medium">
      {{ formatDate(date, 'HH:mm a') }}
    </div>
    <div
      v-if="plannedTime && isPlannedChanged"
      class="text-caption secondary--text"
    >
      <div>
        (Planned:
        <span class="font-weight-medium">{{ formatPlanned }}</span
        >)
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
export default {
  name: 'CheckDateBox',
  props: ['date', 'title', 'time', 'timezone', 'plannedTime'],
  computed: {
    formatPlanned() {
      return moment(this.plannedTime, 'HH:mm').format('HH:mm a')
    },
    isPlannedChanged() {
      return this.formatDate(this.date, 'HH:mm a') !== this.formatPlanned
    },
  },
  methods: {
    formatDate(date, format) {
      if (!this.time && !date) {
        return '-'
      }
      if (this.time) {
        const [hour, minutes] = this.time.split(':')
        return moment(date)
          .tz(this.timezone)
          .set({ h: +hour, m: +minutes })
          .format(format)
      }
      return moment(date).tz(this.timezone).format(format)
    },
  },
}
</script>

<style scoped></style>
