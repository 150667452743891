<template>
  <svg
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linecap: round;
      stroke-linejoin: round;
    "
    xmlns:vectornator="http://vectornator.io"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    version="1.1"
    viewBox="0 0 34 34"
  >
    <defs />
    <g id="Group 1908">
      <g opacity="1">
        <path
          d="M1+17C1+8.16344+8.16344+1+17+1C25.8366+1+33+8.16344+33+17C33+25.8366+25.8366+33+17+33C8.16344+33+1+25.8366+1+17Z"
          opacity="1"
          fill="none"
        />
        <path
          stroke-width="1"
          d="M1.47059+17C1.47059+8.42334+8.42334+1.47059+17+1.47059C25.5767+1.47059+32.5294+8.42334+32.5294+17C32.5294+25.5767+25.5767+32.5294+17+32.5294C8.42334+32.5294+1.47059+25.5767+1.47059+17Z"
          fill="none"
          stroke-linecap="butt"
          opacity="1"
          stroke-linejoin="miter"
        />
      </g>
      <g opacity="1">
        <path
          stroke-width="1"
          d="M17.0006+11.1016L17.0006+22.9016"
          fill="none"
          stroke-linecap="round"
          opacity="1"
          stroke-linejoin="round"
        />
        <path
          stroke-width="1"
          d="M11.1016+17.0006L22.9016+17.0006"
          fill="none"
          stroke-linecap="round"
          opacity="1"
          stroke-linejoin="round"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Add',
}
</script>

<style scoped>
svg {
  stroke: currentColor;
}
</style>
