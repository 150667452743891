<template>
  <v-card
    v-if="reservation"
    elevation="2"
    class="d-flex pointer pa-3 my-3 card-hover"
  >
    <v-avatar tile size="100" class="rounded">
      <img :src="listing.picture" alt="" />
    </v-avatar>
    <div class="flex d-flex px-3 flex-column">
      <div class="text-subtitle-1 font-weight-medium">
        {{ listing.nickname }}
      </div>
      <div class="d-flex align-center text-caption secondary--text">
        <span>
          {{
            formatDate(reservation.check_in, 'MMM Do', listing.timezone)
          }}</span
        >
        <span class="mx-3">-</span>
        <span>
          {{
            formatDate(reservation.check_out, 'MMM Do', listing.timezone)
          }}</span
        >
      </div>
      <span class="text-caption">{{ listing.city_name }}</span>
    </div>
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common-functions'

export default {
  name: 'ReservationBox',
  mixins: [CommonFunctions],
  props: {
    listing: Object,
    reservation: Object,
  },
}
</script>
