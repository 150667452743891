import { render, staticRenderFns } from "./read-terms-btn.vue?vue&type=template&id=433d3c4c&scoped=true"
import script from "./read-terms-btn.vue?vue&type=script&lang=js"
export * from "./read-terms-btn.vue?vue&type=script&lang=js"
import style0 from "./read-terms-btn.vue?vue&type=style&index=0&id=433d3c4c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "433d3c4c",
  null
  
)

export default component.exports