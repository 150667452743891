<template>
  <v-container class="pa-0 login-wrapper d-flex" fluid>
    <v-row class="mx-0">
      <v-col
        v-if="!isMobile"
        cols="6"
        class="signup-image d-flex align-end px-12 py-11"
      >
        <!--        <v-row>-->
        <!--          <v-col cols="12" md="8">-->
        <!--            <reviews />-->
        <!--          </v-col>-->
        <!--        </v-row>-->
      </v-col>
      <v-col cols="12" sm="6" class="d-flex align-center">
        <v-row justify="center">
          <v-col
            v-if="!forgotPassword"
            cols="11"
            lg="7"
            xl="6"
            class="text-center"
            elevation="0"
          >
            <img :src="logo" height="64" alt="" />
            <h2
              class="text-h4 font-weight-bold text-center text-capitalize mb-6"
            >
              Portal sign-up
            </h2>
            <!--            <div class="login-other-way mb-10">-->
            <!--              <v-btn class="mb-4" color="grey lighten-2" block large outlined>-->
            <!--                <v-icon class="mr-3">$google</v-icon>-->
            <!--                <span class="text-capitalize secondary&#45;&#45;text">Sign Up with Google</span>-->
            <!--              </v-btn>-->
            <!--              <v-btn color="grey lighten-2" block large outlined>-->
            <!--                <v-icon class="mr-3">$facebook</v-icon>-->
            <!--                <span class="text-capitalize secondary&#45;&#45;text">Sign Up with Facebook</span>-->
            <!--              </v-btn>-->
            <!--            </div>-->
            <!--            <div class="login-divider p-relative text-center mb-10">-->
            <!--              <span class="login-divider-text p-relative white secondary&#45;&#45;text px-5">or sign up with email</span>-->
            <!--            </div>-->
            <v-form ref="signup" lazy-validation class="mb-8" @submit="signup">
              <v-text-field
                v-model="firstName"
                name="firstName"
                :rules="nameRules"
                label="First Name"
                type="text"
                outlined
                dense
              />
              <v-text-field
                v-model="lastName"
                name="lastName"
                :rules="nameRules"
                label="Last Name"
                type="text"
                outlined
                dense
              />
              <v-text-field
                v-model="email"
                name="email"
                :rules="emailRules"
                label="Email"
                type="text"
                outlined
                dense
                @input="formChange"
              />
              <v-text-field
                v-model="password"
                name="password"
                :rules="passwordRules"
                label="Password"
                type="password"
                outlined
                dense
              />
              <v-text-field
                v-model="repeatPassword"
                name="repeatPassword"
                label="Confirm password"
                :rules="
                  passwordRules.concat(
                    password === repeatPassword || 'Passwords are not matched'
                  )
                "
                type="password"
                outlined
                dense
              />
              <div v-if="error" class="text-caption error--text">
                {{ error }}
              </div>
              <v-btn
                type="submit"
                :loading="signupLoading"
                color="primary"
                class="text-capitalize"
                elevation="0"
                block
                x-large
              >
                <span class="text-capitalize">Create an account</span>
              </v-btn>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import formRules from 'components/mixins/form-rules-mixin'
import deviceMixin from 'components/mixins/deviceMixin'

export default {
  name: 'SignupPage',
  mixins: [formRules, deviceMixin],
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      repeatPassword: '',
      error: '',
      signupLoading: false,
    }
  },
  computed: {
    logo() {
      const { logo } = this.$route.query
      return logo
    },
  },
  mounted() {
    const { fullName } = this.$route.query
    const [firstName, lastName] = fullName.split(' ')
    this.firstName = firstName
    this.lastName = lastName
  },
  methods: {
    formChange() {
      this.error = ''
    },
    signup(e) {
      e.preventDefault()
      const { email, password, firstName, lastName } = this
      const isValid = this.$refs.signup.validate()
      const { g, externalCheckout } = this.$route.query
      if (isValid) {
        this.signupLoading = true
        axios
          .post('/api/tourist/signup', {
            email,
            password,
            gId: g,
            firstName,
            lastName,
            externalCheckout,
          })
          .then(({ data }) => {
            this.signupLoading = false

            if (data.redirectUrl) {
              location.href = data.redirectUrl
              return
            }
            this.$router.push(`/tourist/trips/`)
          })
          .catch(error => {
            this.signupLoading = false
            if (error.response) {
              this.error = error.response.data.error
            }
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.login {
  &-wrapper {
    min-height: 100%;
  }
  &-divider {
    &::before {
      position: absolute;
      background-color: #dfe1e6;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
      content: '';
    }
    &-text {
      z-index: 1;
    }
  }
}
</style>
