import axios from 'axios'

export default {
  state: {
    flags: {},
    flagsFetched: false,
  },
  mutations: {
    updateFeatureFlags(state, data) {
      state.flags = data
      state.flagsFetched = true
    },
  },
  actions: {
    async getFeatureFlags({ commit }) {
      try {
        const { data } = await axios.get(`/api/feature-flags`)
        commit('updateFeatureFlags', data)
      } catch (err) {
        console.error(err)
      }
    },
  },
  getters: {
    featureFlags(state) {
      return state.flags
    },
    flagsFetched(state) {
      return state.flagsFetched
    },
  },
}
