var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{class:{ 'd-inline-block mx-0 flex-grow-0': !_vm.thumbnails }},[(_vm.thumbnails)?_c('div',{staticClass:"d-flex flex-wrap flex"},[(_vm.uploadable)?_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":_vm.buttonWidth || _vm.buttonHeight ? 12 : _vm.cols}},[_c('div',{staticClass:"box",style:({
          height: _vm.buttonHeight ? _vm.buttonHeight + 'px' : '100%',
          paddingTop: _vm.buttonHeight ? _vm.buttonHeight : '100%',
        })},[_c('media-upload',{attrs:{"is-android":_vm.isAndroid,"thumbnail":true,"button-height":_vm.buttonHeight,"disabled":_vm.disabled,"uploading":_vm.uploading,"button-text":_vm.buttonText,"on-change":_vm.imageUploaded},on:{"update:uploading":function($event){_vm.uploading=$event}}})],1)]):_vm._e(),_vm._l((_vm.images),function(img){return _c('v-col',{key:img,attrs:{"cols":_vm.cols}},[(img !== 'loading')?_c('div',{class:_vm.isVideo(img) ? 'video-box' : 'box',style:({
          height: _vm.buttonHeight ? _vm.buttonHeight + 'px' : '100%',
          paddingTop: _vm.buttonHeight ? _vm.buttonHeight : '100%',
        })},[_c('ImageView',{staticClass:"shover-image-view",attrs:{"readonly":_vm.readonly,"on-remove":_vm.removeImg,"src":img,"small-width":"100%"},on:{"click":_vm.imageClicked}})],1):_c('div',{staticClass:"box"},[_c('v-skeleton-loader',{attrs:{"width":"100%","height":"100%","type":"image"}})],1)])})],2):_c('v-icon',{attrs:{"color":_vm.iconColor},on:{"click":function($event){$event.stopPropagation();return _vm.imageClicked.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.icon)+" ")]),(_vm.dialog)?_c('v-dialog',{staticClass:"white",attrs:{"content-class":"rounded-xl","width":_vm.zoomIn ? 1600 : 900},on:{"click:outside":function($event){_vm.currImage = null}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-sheet',{staticClass:"rounded-xl overflow-auto"},[_c('v-carousel',{attrs:{"height":"auto","hide-delimiters":_vm.images.length == 1},model:{value:(_vm.currImage),callback:function ($$v) {_vm.currImage=$$v},expression:"currImage"}},_vm._l((_vm.images),function(slide){return _c('v-carousel-item',{key:slide,attrs:{"value":slide}},[(_vm.isVideo(slide) && _vm.currImage === slide)?_c('video',{attrs:{"width":"100%","autoplay":"","controls":"","height":"500","src":slide}}):_c('v-img',{staticClass:"zoom-hover",class:{ 'image-zoom': _vm.zoomIn },attrs:{"contain":"","src":slide},on:{"click":_vm.zoom}})],1)}),1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }