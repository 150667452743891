<template>
  <div>
    <v-app-bar
      app
      fixed
      outlined
      light
      class="px-0 px-sm-6 mx-xs-0 tourist-app-bar"
      height="60"
      elevate-on-scroll
    >
      <v-app-bar-nav-icon
        v-if="isMobile"
        class="black--text"
        @click.stop="drawer = !drawer"
      />
      <v-row class="app-bar-container align-center">
        <img :src="logo" height="40" class="ma-5" />
        <div v-if="user.id" class="flex">
          <v-tabs
            v-if="!isMobile"
            v-model="tab"
            height="60"
            background-color="transparent"
            slider-size="3"
            color="black"
          >
            <v-tab href="#my-trips" to="/tourist/trips">
              <v-icon v-if="isMobile" color="black"> fas fa-suitcase </v-icon>
              <div v-else>{{ $t('My Trips') }}</div>
            </v-tab>
            <v-tab v-if="storeEnabled" href="#store" to="/tourist/store">
              <v-icon v-if="isMobile" color="black"> fas fa-store </v-icon>
              <div v-else>{{ $t('Store') }}</div>
            </v-tab>
          </v-tabs>
        </div>
        <v-spacer />
        <locale-changer-header class="mr-2"></locale-changer-header>
        <v-menu
          v-if="user.id"
          bottom
          left
          min-width="200px"
          rounded="xl"
          offset-x
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn icon x-large class="ml-4" v-on="on">
              <v-avatar color="black" size="35">
                <img
                  v-if="user.guest && user.guest.picture"
                  :src="user.guest.picture"
                  alt="John"
                />
                <v-icon
                  v-else
                  size="15"
                  color="primary"
                  class="pa-3 rounded-xl black"
                >
                  fas fa-user-alt
                </v-icon>
              </v-avatar>
            </v-btn>
          </template>
          <v-card color="black--text">
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <div class="text-overline text-capitalize">
                  {{ user.first_name }}
                </div>
              </div>
            </v-list-item-content>
            <v-divider v-if="storeEnabled" />
            <v-list-item-content v-if="storeEnabled" class="justify-center">
              <v-btn
                :ripple="false"
                class="text-caption"
                color="black"
                to="/tourist/store/history"
                plain
                text
              >
                {{ $t('Purchase history') }}
              </v-btn>
            </v-list-item-content>
            <v-divider />
            <v-list-item-content class="justify-center">
              <v-btn
                :ripple="false"
                color="black"
                class="text-caption"
                plain
                text
                @click="logout"
              >
                {{ $t('Disconnect') }}
              </v-btn>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer
      v-if="isMobile"
      v-model="drawer"
      absolute
      temporary
      :right="isRtl"
    >
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="primary--text text--accent-4"
        >
          <v-list-item to="/tourist/trips">
            <v-list-item-title class="d-flex align-center">
              <v-icon size="20" class="me-3"> fas fa-suitcase </v-icon>
              <span> {{ $t('My Trips') }} </span>
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="storeEnabled" to="/tourist/store">
            <v-list-item-title class="d-flex align-center">
              <v-icon size="20" class="me-3"> fas fa-shopping-bag </v-icon>
              <span> {{ $t('Store') }} </span>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import deviceMixin from 'components/mixins/deviceMixin'
import localeChangerHeader from 'components/locale-changer-header.vue'
import { mapGetters, mapState } from 'vuex'
import get from 'lodash/fp/get'
export default {
  name: 'TouristBar',
  components: {
    localeChangerHeader,
  },
  mixins: [deviceMixin],
  data() {
    return {
      drawer: false,
      group: null,
      tab: 'my-trips',
      buttonLoading: false,
    }
  },
  computed: {
    ...mapState({
      reservation: state => state.tourist.reservationBasicInfo,
    }),
    ...mapGetters(['reservations']),
    user() {
      return this.$store.getters.user || { guest: {} }
    },
    logo() {
      return this.companyLogo || get('listing.public_logo', this.reservation)
    },
  },
  watch: {
    group() {
      this.drawer = false
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
    },
  },
}
</script>

<style scoped>
.theme--light.v-icon {
  color: inherit;
}
.tourist-app-bar {
  border: none;
  border-bottom: 1px solid var(--v-secondary-lighten1);
  background-color: white !important;
}
.new-item {
  animation-name: blinking-bg;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}

@-webkit-keyframes blinking-bg {
  0% {
    background-color: white;
  }
  100% {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
</style>
