<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.009"
    height="9.379"
    viewBox="0 0 21.009 9.379"
  >
    <path
      d="M13.79,11.431a.638.638,0,0,0,0,.9l2.97,2.975H-1.495a.633.633,0,0,0-.63.635.633.633,0,0,0,.63.635H16.75l-2.97,2.975a.643.643,0,0,0,0,.9.632.632,0,0,0,.894,0L18.7,16.389h0a.713.713,0,0,0,.132-.2.606.606,0,0,0,.049-.244A.637.637,0,0,0,18.7,15.5l-4.025-4.054A.622.622,0,0,0,13.79,11.431Z"
      transform="translate(2.125 -11.252)"
    />
  </svg>
</template>

<script>
export default {
  name: 'LongRightArrow',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
