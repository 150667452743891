<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters class="fill-height">
      <v-col cols="12" class="flex overflow-y-auto white d-flex justify-center">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'TripView',
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        if (!from || to.params.id !== from.params.id) {
          this.$store.dispatch('getTouristTripInfo', this.$route.params.id)
        }
      },
    },
  },
}
</script>

<style scoped></style>
