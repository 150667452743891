<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.156"
    height="14.509"
    viewBox="0 0 10.156 14.509"
  >
    <path
      d="M12.578,3A5.074,5.074,0,0,0,7.5,8.078c0,3.809,5.078,9.431,5.078,9.431s5.078-5.622,5.078-9.431A5.074,5.074,0,0,0,12.578,3Zm0,6.892a1.814,1.814,0,1,1,1.814-1.814A1.814,1.814,0,0,1,12.578,9.892Z"
      transform="translate(-7.5 -3)"
    />
  </svg>
</template>

<script>
export default {
  name: 'MapMarker',
}
</script>

<style scoped>
svg {
  fill: #fff;
}
</style>
