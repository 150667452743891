<template>
  <v-container class="pa-0 login-wrapper d-flex" fluid>
    <v-row class="mx-0">
      <v-col
        v-if="!isMobile"
        cols="6"
        class="login-image d-flex align-end px-12 py-11"
      >
        <!--        <v-row>-->
        <!--          <v-col cols="12" md="8">-->
        <!--            <reviews />-->
        <!--          </v-col>-->
        <!--        </v-row>-->
      </v-col>
      <v-col cols="12" sm="6" class="d-flex align-center">
        <v-row justify="center">
          <v-col cols="11" lg="7" xl="6" class="p-relative" elevation="0">
            <div v-if="!forgotPassword" class="text-center">
              <h2
                class="text-h4 font-weight-bold text-center text-capitalize mb-6"
              >
                Portal login
              </h2>
              <v-form @submit="login">
                <v-text-field
                  v-model="email"
                  name="email"
                  tabindex="1"
                  label="Email"
                  type="text"
                  outlined
                  dense
                  @input="formChange"
                />
                <v-text-field
                  v-model="password"
                  name="password"
                  label="Password"
                  type="password"
                  outlined
                  tabindex="2"
                  dense
                  @input="formChange"
                />
                <div
                  class="d-flex flex-wrap justify-space-between align-center"
                >
                  <v-checkbox
                    v-model="rememberUser"
                    class="login-remember my-0"
                    label="Remember me"
                    color="primary"
                    hide-details
                    dense
                  />
                  <button
                    type="button"
                    class="info--text mt-1"
                    @click="forgotPassword = true"
                  >
                    Forgot Password?
                  </button>
                </div>
                <v-btn
                  tabindex="3"
                  type="submit"
                  color="primary"
                  class="mt-8"
                  elevation="0"
                  :loading="loginLoading"
                  block
                  x-large
                >
                  <span class="text-capitalize">Login</span>
                </v-btn>
                <div v-if="error" class="text-caption error--text">
                  {{ error }}
                </div>
              </v-form>
            </div>
            <ForgotPassword
              v-else
              @on-forgot-password-close="forgotPassword = false"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import formRules from 'components/mixins/form-rules-mixin'
import deviceMixin from 'components/mixins/deviceMixin'
import ForgotPassword from 'components/forgot-password'

export default {
  name: 'LoginPage',
  components: {
    ForgotPassword,
  },
  mixins: [formRules, deviceMixin],
  data() {
    return {
      email: '',
      password: '',
      error: '',
      loginLoading: false,
      rememberUser: false,
      forgotPassword: false,
    }
  },
  async mounted() {
    if (this.$route.params.status !== 'not_authorized') {
      try {
        await this.$store.dispatch('getCurrentUser')
        if (this.$store.getters.user) {
          this.$router.push({ name: 'tourist-trips' })
        }
      } catch (err) {
        console.log('Not authorized')
      }
    }
  },
  methods: {
    formChange() {
      this.error = ''
    },
    async login(e) {
      e.preventDefault()
      const { email, password } = this
      try {
        this.loginLoading = true
        const { data } = await axios.post('/api/login-portal', {
          email,
          password,
        })
        this.$store.commit('updateUser', data)
        this.$router.push({
          name: 'tourist-trips',
          params: { afterLogin: true },
        })
        this.loginLoading = false
      } catch (error) {
        this.loginLoading = false
        if (error.response) {
          this.error = error.response.data.error
        } else {
          console.log(error)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.login {
  &-wrapper {
    min-height: 100%;
  }
  &-divider {
    &::before {
      position: absolute;
      background-color: #dfe1e6;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
      content: '';
    }
    &-text {
      z-index: 1;
    }
  }
}
</style>
