<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25.541"
    viewBox="0 0 25 25.541"
  >
    <path
      class="a"
      d="M42.369,0A12.763,12.763,0,0,0,30.892,7.114l4.3,3.287a7.541,7.541,0,0,1,7.173-5.176,7.4,7.4,0,0,1,4.709,1.684L50.8,3.193A12.735,12.735,0,0,0,42.369,0Z"
      transform="translate(-29.58 0)"
    />
    <path
      class="b"
      d="M30.9,7.113,35.2,10.4a7.541,7.541,0,0,1,7.173-5.176,7.4,7.4,0,0,1,4.709,1.684l3.72-3.715A12.742,12.742,0,0,0,42.376,0"
      transform="translate(-29.588 0)"
    />
    <path
      class="c"
      d="M10.516,144.529c0-.118.009-.234.014-.35a7.613,7.613,0,0,1,.369-2.021l-4.3-3.287a12.749,12.749,0,0,0-1.3,5.307c0,.117-.01.233-.01.35s.007.234.01.351a12.759,12.759,0,0,0,1.3,5.3l4.3-3.293a7.59,7.59,0,0,1-.366-2.01C10.525,144.763,10.516,144.647,10.516,144.529Z"
      transform="translate(-5.284 -131.759)"
    />
    <g transform="translate(1.31 7.113)">
      <path
        class="d"
        d="M30.86,150.182l4.3-3.293a7.591,7.591,0,0,1-.366-2.01c-.005-.117-.014-.233-.014-.351s.009-.234.014-.35a7.612,7.612,0,0,1,.369-2.021l-4.3-3.286"
        transform="translate(-30.86 -138.872)"
      />
    </g>
    <path
      class="e"
      d="M46.616,299.667a7.912,7.912,0,0,1-4.247,1.117,7.565,7.565,0,0,1-7.174-5.175l-4.3,3.286a12.836,12.836,0,0,0,14.9,6.652,11.842,11.842,0,0,0,4.909-2.721Z"
      transform="translate(-29.58 -280.467)"
    />
    <path
      class="f"
      d="M30.9,359.768a12.836,12.836,0,0,0,14.9,6.652,11.841,11.841,0,0,0,4.909-2.721l-4.085-3.158a7.912,7.912,0,0,1-4.247,1.117,7.562,7.562,0,0,1-4.453-1.452"
      transform="translate(-29.588 -341.34)"
    />
    <path
      class="g"
      d="M269.082,201.32h-11.8v4.917h6.74a6.177,6.177,0,0,1-2.612,3.971l4.085,3.158a11.726,11.726,0,0,0,2.72-3.855,14.357,14.357,0,0,0,1.154-5.811A11.614,11.614,0,0,0,269.082,201.32Z"
      transform="translate(-244.376 -191.008)"
    />
    <g transform="translate(12.908 10.312)">
      <path
        class="h"
        d="M417.636,213.366a11.81,11.81,0,0,0,2.729-3.869,14.321,14.321,0,0,0,1.15-5.8,11.561,11.561,0,0,0-.295-2.373h-.734"
        transform="translate(-409.423 -201.32)"
      />
      <path
        class="h"
        d="M257.284,241.32v2.868h6.556"
        transform="translate(-257.284 -239.271)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Google',
}
</script>

<style scoped>
.a {
  fill: #ff7976;
}
.b {
  fill: #e3443a;
}
.c {
  fill: #f4d72c;
}
.d {
  fill: #f7b92b;
}
.e {
  fill: #59c96e;
}
.f {
  fill: #40a557;
}
.g {
  fill: #0faef4;
}
.h {
  fill: #4087ed;
}
</style>
