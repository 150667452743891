<template>
  <div>
    <v-overlay v-if="loading" color="white" light absolute opacity="0.9">
      <v-progress-circular color="primary" indeterminate size="50" />
    </v-overlay>
    <div id="chekin-container"></div>
  </div>
</template>

<script>
import { CHEKIN_SDK_CDN } from '@/consts'

export default {
  name: 'ChekinGuestSdk',
  props: {
    apiKey: {
      type: String,
      required: true,
    },
    reservationId: {
      type: String,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
    preCheckIn: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
    }
  },
  mounted() {
    this.loadSdkScript()
  },
  methods: {
    loadSdkScript() {
      const script = document.createElement('script')
      script.src =
        process.env.NODE_ENV === 'production'
          ? CHEKIN_SDK_CDN.PRODUCTION
          : CHEKIN_SDK_CDN.STAGING
      script.onload = this.initializeApi
      document.head.appendChild(script)
    },
    initializeApi() {
      const api = new window.ChekinPro()
      const defaultLanguage = this.$i18n.locale

      const init = () => {
        if (document.getElementById('chekin-container')) {
          api.initialize({
            apiKey: this.apiKey,
            reservationId: this.reservationId,
            defaultLanguage,
            canEditReservationDetails: false,
            enableGuestsRemoval: false,
            onGuestRegistered: this.onGuestRegistered,
          })
          api.renderApp({ targetNode: 'chekin-container' })
          this.checkIframeLoaded()
        } else {
          setTimeout(init, 100)
        }
      }

      init()
    },
    checkIframeLoaded() {
      const checkIframe = () => {
        if (document.getElementById('chekin-root-iframe')) {
          this.loading = false
        } else {
          setTimeout(checkIframe, 100)
        }
      }
      checkIframe()
    },
    onGuestRegistered(data) {
      this.mapGuestObjectToRecord(data)
    },
    updatePreCheckIn() {
      this.$store.dispatch('saveTouristPreCheckIn', {
        ...this.preCheckIn,
        step: this.step,
      })
    },
    getAge(birthday) {
      const today = new Date()
      const birthDate = new Date(birthday)
      let age = today.getFullYear() - birthDate.getFullYear()
      const monthDifference = today.getMonth() - birthDate.getMonth()

      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--
      }

      return age
    },
    mapGuestObjectToRecord(guestObject) {
      const phone = guestObject.phone
      const fullName = `${guestObject.name} ${guestObject.surname}`

      const guestData = {
        full_name: fullName,
        phone_number: phone ? `${phone.code}${phone.number}` : null,
        email: guestObject.email,
        id:
          guestObject.document_type.label === 'Identity Card'
            ? guestObject.document_number
            : null,
        passport:
          guestObject.document_type.label === 'Passport'
            ? guestObject.document_number
            : null,
        driving_licence:
          guestObject.document_type.label === 'Driving Licence'
            ? guestObject.document_number
            : null,
        address: guestObject.residence_address || null,
      }

      const filteredGuestData = Object.fromEntries(
        Object.entries(guestData).filter(([key, value]) => value)
      )

      this.preCheckIn.guests_details.push(filteredGuestData)
      this.preCheckIn.guest_count_info.guests =
        this.preCheckIn.guests_details.map(guest => guest.full_name)

      const age = this.getAge(guestObject.birth_date)
      if (age < 13 && this.preCheckIn.guest_count_info.adults > 2) {
        this.preCheckIn.guest_count_info.adults--
        if (age >= 2) {
          this.preCheckIn.guest_count_info.kids++
        } else {
          this.preCheckIn.guest_count_info.babies++
        }
      }

      this.updatePreCheckIn()
    },
  },
}
</script>

<style scoped>
#chekin-container {
  width: 100%;
  height: 70vh;
}
</style>
