import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import icons from '@/assets/icons'
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#44a2a2',
        secondary: {
          base: '#8F90A6',
          lighten1: '#F2F2F2',
          lighten5: '#FAF9F8',
          darken1: '#464673',
        },
        tertiary: '#000000',
        accent: '#9b8ea4',
        darkpurle: '#464673',
        error: '#A01622',
        success: '#1AA872',
        pending: '#FFD54F',
      },
    },
  },
  icons: {
    iconfont: 'fa',
    ...icons,
  },
})
