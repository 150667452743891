<template>
  <div>
    <v-btn
      v-if="isMobile"
      color="secondary"
      elevation="0"
      block
      outlined
      tile
      @click="shown = !shown"
    >
      <v-icon class="mr-2" small>$filters</v-icon>
      {{ $t('Filters') }}
    </v-btn>
    <v-expand-transition>
      <v-card
        v-show="!isMobile || shown"
        class="mt-4 mt-sm-0 pa-6"
        elevation="2"
      >
        <div class="d-flex justify-space-between text-body-2">
          <span class="font-weight-medium text-uppercase">
            {{ $t('Filters') }} ({{ selected.length }})
          </span>
          <button class="secondary--text" @click="clear">
            {{ $t('Clear all') }}
          </button>
        </div>
        <v-divider class="my-4" />
        <span class="font-weight-bold">
          {{ $t('Categories') }}
        </span>
        <v-list dense>
          <v-list-item v-for="t in items" :key="t" class="pl-0">
            <v-checkbox
              class="mt-0"
              :label="$t(t)"
              :value="t"
              :input-value="selected"
              dense
              hide-details
              @change="select(t)"
            />
          </v-list-item>
        </v-list>
      </v-card>
    </v-expand-transition>
  </div>
</template>

<script>
import deviceMixin from 'components/mixins/deviceMixin'

export default {
  name: 'StoreItemsFilters',
  mixins: [deviceMixin],
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      topics: ['Addons', 'Amenities', 'Services'],
      shown: false,
    }
  },
  methods: {
    select(t) {
      const items = this.selected
      const index = items.indexOf(t)
      if (index > -1) {
        items.splice(index, 1)
      } else {
        items.push(t)
      }
      this.$emit('update:selected', items)
    },
    clear() {
      this.$emit('update:selected', [])
    },
  },
}
</script>

<style scoped></style>
