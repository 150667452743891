var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-stepper-content',{staticClass:"pa-0",attrs:{"step":_vm.step}},[_c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"text-h6 mb-2 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('Special Requests'))+" ")]),(_vm.specialRequest.length)?_c('v-row',{staticClass:"align-center align-md-start flex-column"},[_vm._l((_vm.specialRequest),function(request,idx){return _c('v-col',{key:idx,attrs:{"cols":"12","md":"4"}},[(request.type === 'counter')?_c('AddRemoveItem',{attrs:{"label":_vm.$t(request.key),"value":request.amount,"max":request.max},on:{"update:value":function($event){return _vm.$set(request, "amount", $event)}}}):_c('div',{staticClass:"d-flex justify-space-around"},[_c('div',{staticClass:"flex-grow-1"},[_vm._v(_vm._s(_vm.$t(request.key)))]),_c('v-radio-group',{staticClass:"font-weight-medium mt-0 pt-0",staticStyle:{"margin-right":"-15px"},attrs:{"hide-details":"","row":""},model:{value:(request.amount),callback:function ($$v) {_vm.$set(request, "amount", $$v)},expression:"request.amount"}},[_c('v-radio',{attrs:{"label":_vm.$t('Yes'),"value":1}}),_c('v-radio',{attrs:{"label":_vm.$t('No'),"value":0}})],1)],1)],1)}),_c('v-col',{staticClass:"text-caption red--text font-weight-medium",attrs:{"cols":"12","md":"4"}},_vm._l((_vm.errors),function(err){return _c('div',{key:err},[_vm._v(" "+_vm._s(err)+" ")])}),0)],2):_c('span',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.$t('No Special requests available'))+" ")]),(
        _vm.checkinInfo.offer_parking ||
        (_vm.isDesignedVr &&
          (_vm.preCheckIn.has_assigned_parking ||
            _vm.preCheckIn.has_parking_pass ||
            _vm.preCheckIn.listing_num_of_rooms >= 3))
      )?_c('div',[_c('v-divider',{staticClass:"my-4"}),_c('div',{staticClass:"text-h6 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('Do you need parking place?'))+" ")]),_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.clientInfo.need_parking),callback:function ($$v) {_vm.$set(_vm.clientInfo, "need_parking", $$v)},expression:"clientInfo.need_parking"}},[_c('v-radio',{attrs:{"label":_vm.$t('Yes'),"value":true}}),_c('v-radio',{attrs:{"label":_vm.$t('No'),"value":false}})],1),(_vm.preCheckIn.has_parking_pass && _vm.clientInfo.need_parking)?_c('span',{staticClass:"warning--text my-2"},[_vm._v(" A parking pass will be available for purchase from our store. ")]):_vm._e(),(_vm.clientInfo.need_parking)?_c('div',[(
            _vm.preCheckIn.has_assigned_parking || _vm.preCheckIn.has_parking_pass
          )?_c('v-form',{ref:"form",staticClass:"flex d-flex"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"grey--text pb-3",attrs:{"cols":"12"}},[_vm._v(" If you don't have the details yet you would be able to update this form again on your arrival date ")]),_c('v-col',{staticClass:"pr-3",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('CarModel'),"prepend-inner-icon":"directions_car"},model:{value:(_vm.clientInfo.car_model),callback:function ($$v) {_vm.$set(_vm.clientInfo, "car_model", $$v)},expression:"clientInfo.car_model"}})],1),_c('v-col',{staticClass:"pr-3",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('LicencePlate'),"prepend-inner-icon":"straighten"},model:{value:(_vm.clientInfo.licence_plate),callback:function ($$v) {_vm.$set(_vm.clientInfo, "licence_plate", $$v)},expression:"clientInfo.licence_plate"}})],1)],1)],1):_c('AddRemoveItem',{attrs:{"label":_vm.$t('How many cars?'),"value":_vm.clientInfo.car_amount,"min":1,"max":_vm.parkingSpaces,"description":_vm.carAmountWarn},on:{"update:value":function($event){return _vm.$set(_vm.clientInfo, "car_amount", $event)}}})],1):_vm._e()],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }