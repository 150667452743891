<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          class="flex dvr-datetime text-caption flex-column pa-2"
          v-on="on"
        >
          <div class="flex text-caption text-uppercase">
            {{ label }}
          </div>
          <div class="flex text-body-2 font-weight-medium">
            {{ date }}
          </div>
        </div>
      </template>
      <v-time-picker
        v-model="date"
        ampm-in-title
        :min="min"
        :max="max"
        :allowed-minutes="allowedStep"
        @change="change"
        @click:hour="clickHour"
        @click:minute="$refs.menu.save(date)"
      />
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'DvrTimePicker',
  props: {
    borderRadius: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: String,
    },
    format: {
      type: String,
      default: 'HH:mm',
    },
    min: {
      type: String,
    },
    max: {
      type: String,
    },
  },
  data() {
    return {
      date: this.value,
      menu: false,
    }
  },
  methods: {
    clickHour(val) {
      this.$emit('update:value', `${val}:00`)
    },
    change(val) {
      this.$emit('update:value', val)
      this.menu = false
    },

    allowedStep: m => m % 30 === 0,
  },
}
</script>

<style scoped>
.dvr-datetime {
  height: 60px;
  border-radius: 8px;
  width: 150px;
  border: 1px solid #d5d5d5;
}
</style>
