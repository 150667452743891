import get from 'lodash/fp/get'
export default {
  computed: {
    companyName() {
      return get('state.user.tenant.name', this.$store)
    },
    companyLogo() {
      return get('state.user.configuration.logo_url', this.$store)
    },
    companyNameConf() {
      return get('state.user.configuration.company_name', this.$store)
    },
    storeTax() {
      const tax = get('state.user.configuration.store_tax', this.$store)
      return Number((tax * 100).toFixed(2))
    },
    storeEnabled() {
      return get('state.user.configuration.store_support', this.$store)
    },
    superhogEnabled() {
      return get('state.user.configuration.superhog_support', this.$store)
    },
    chekinGuestKey() {
      return get('state.user.configuration.chekin_guest_api_key', this.$store)
    },
    isRtl() {
      return this.$vuetify.rtl
    },
  },
}
