<template>
  <div class="flex">
    <div class="d-flex justify-space-between align-center mb-2">
      <div class="text-h6 font-weight-medium">
        {{ $t('ReservationDetails') }}
      </div>
      <div class="text-caption pointer mr-3" @click="goToPreCheckIn">
        {{ $t('Edit') }} >
      </div>
    </div>
    <div>
      <div class="text-subtitle-2 font-weight-medium">
        {{ $t('WhosComing') }}
      </div>
      <div class="text-caption">{{ totalGuests }} {{ $t('Guests') }}</div>
    </div>
    <div class="my-2">
      <div class="text-subtitle-2 font-weight-medium">
        {{ $t('SpecialRequests') }}
      </div>
      <div v-for="(req, i) in specialRequests" :key="i" class="text-caption">
        {{ req }}
      </div>
    </div>
    <div v-if="parkingInfo.has_info">
      <div class="text-subtitle-2 font-weight-medium">{{ $t('Parking') }}</div>
      <div class="text-caption">
        <span class="font-weight-medium">Model:</span>
        <span>{{ parkingInfo.model }}</span>
      </div>
      <div class="text-caption">
        <span class="font-weight-medium">Plate number:</span>
        <span>{{ parkingInfo.license_plate }}</span>
      </div>
      <div v-if="parkingInfo.assigned_parking_desc" class="text-caption">
        <span class="font-weight-medium">Description:</span>
        <span>{{ parkingInfo.assigned_parking_desc }}</span>
      </div>
      <div v-if="parkingInfo.parking_pass_desc" class="text-caption">
        <span class="font-weight-medium">Description:</span>
        <span>{{ parkingInfo.parking_pass_desc }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import pick from 'lodash/fp/pick'
import pickBy from 'lodash/fp/pickBy'
import compose from 'lodash/fp/compose'
import sum from 'lodash/fp/sum'
import values from 'lodash/fp/values'
import identity from 'lodash/fp/identity'
import keys from 'lodash/fp/keys'
const sumGuestByInfo = compose(sum, values, pick(['adults', 'kids', 'babies']))
const extractSpecialRequests = compose(keys, pickBy(identity))
export default {
  name: 'PreCheckInSummary',
  computed: {
    totalGuests() {
      return sumGuestByInfo(this.reservationInfo.guest_count_info)
    },
    specialRequests() {
      return extractSpecialRequests(this.reservationInfo.special_requests)
    },
    reservationInfo() {
      return this.$store.getters.reservationInfo
    },
    parkingInfo() {
      return this.reservationInfo.car_info || {}
    },
  },
  methods: {
    goToPreCheckIn() {
      this.$router.push({
        name: 'pre-check-in',
        params: { step: '1', id: this.$route.params.id },
      })
    },
    formatRequestText(request) {
      return request.amount >= 2
        ? `${request.amount} X `
        : '' + `${request.key}`
    },
  },
}
</script>

<style scoped></style>
