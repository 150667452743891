<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
  >
    <g transform="translate(0.25)">
      <ellipse
        class="a"
        cx="13"
        cy="13"
        rx="13"
        ry="13"
        transform="translate(-0.25 0)"
      />
      <path
        class="b"
        d="M45.318,30.229H43v8.5H39.484v-8.5H37.812V27.242h1.672V25.309a3.3,3.3,0,0,1,3.546-3.546l2.6.011v2.9H43.744a.715.715,0,0,0-.745.814v1.758h2.627Z"
        transform="translate(-29.062 -16.72)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Facebook',
}
</script>

<style scoped>
.a {
  fill: #3b5998;
}
.b {
  fill: #fff;
}
</style>
