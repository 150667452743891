import axios from 'axios'
import get from 'lodash/fp/get'
import orderBy from 'lodash/fp/orderBy'
import router from '@/router'

export default {
  state: {
    reservations: [],
    touristPreCheckIn: null,
    tripInfo: null,
    hasRemainingBalance: false,
    shouldPlaceDeposit: false,
    reservationsFetched: false,
    purchaseHistory: [],
    loading: false,
    trackedListingTask: null,
    reservationItems: {},
    reservationBasicInfo: {},
  },
  mutations: {
    updateReservations(state, data) {
      state.reservations = data
    },
    updateTouristLoading(state, data) {
      state.loading = data
    },
    updateTouristPreCheckIn(state, data) {
      state.touristPreCheckIn = data
      const resIndex = state.reservations.findIndex(
        r => r.id === data.reservation_id
      )
      if (resIndex !== -1) {
        state.reservations[resIndex].pre_check_in = data
      }
    },
    updateRemainingBalance(state, data) {
      state.hasRemainingBalance = data
    },
    updateShouldPlacedDeposit(state, data) {
      state.shouldPlaceDeposit = data
    },
    updateTouristTripInfo(state, data) {
      state.tripInfo = data
    },
    updateReservationsFetched(state) {
      state.reservationsFetched = true
    },
    updatePurchaseHistory(state, data) {
      state.purchaseHistory = data
    },
    updateReservationItems(state, { confirmationCode, data }) {
      state.reservationItems = {
        ...state.reservationItems,
        [confirmationCode]: data,
      }
    },
    setReservationBasicInfo(state, data) {
      state.reservationBasicInfo = data
    },
    updateTrackedListingTask(state, data) {
      state.trackedListingTask = data
    },
  },
  actions: {
    async getReservations({ commit }) {
      try {
        const { data } = await axios.get(`/api/tourist/reservations/`)
        commit('updateReservations', data)
      } catch (err) {
        console.error(err)
      }
      commit('updateReservationsFetched', true)
    },
    getTouristTripInfo: function (context, reservationConfimation) {
      context.commit('updateTouristLoading', true)
      const confirmationCode = encodeURIComponent(reservationConfimation)
      return axios
        .get(`/api/tourist-data/${confirmationCode}`)
        .then(function ({ data }) {
          if (data.need_pre_checkin) {
            router.push(`/tourist/trips/${confirmationCode}/info`)
          } else {
            context.commit('updateTouristTripInfo', data)
            context.commit('updateShouldPlacedDeposit', false)
          }
        })
        .catch(function (err) {
          const { public_data, error } = get('response.data', err)
          if (err.response.status === 404) {
            context.commit('showNotFound')
          } else if (error === 'deposit_not_paid') {
            context.commit('updateShouldPlacedDeposit', true)
          }
          if (public_data) {
            context.commit('updateTouristTripInfo', public_data)
          }
        })
        .finally(() => {
          context.commit('updateTouristLoading', false)
        })
    },
    saveTouristPreCheckIn: function (context, data) {
      return axios
        .post('/api/check-in/' + context.state.touristPreCheckIn.id, data)
        .then(({ data }) => {
          context.commit('updateTouristPreCheckIn', data)
        })
        .catch(function (error) {
          alert(error)
        })
    },
    getTouristPreCheckIn({ commit }, reservationConfimation) {
      commit('updateTouristLoading', true)
      const confirmationCode = encodeURIComponent(reservationConfimation)
      return axios
        .get(`/api/tourist/reservations/${confirmationCode}/check-in`)
        .then(({ data }) => {
          commit('updateTouristPreCheckIn', data)
          commit('updateRemainingBalance', false)
        })
        .catch(err => {
          if (err.response.data.error === 'not_paid') {
            commit('updateRemainingBalance', true)
          } else if (err.response.status === 404) {
            commit('showNotFound')
          }
        })
        .then(() => {
          commit('updateTouristLoading', false)
        })
    },
    getReservationStoreItems({ commit }, reservationConfimation) {
      const confirmationCode = encodeURIComponent(reservationConfimation)

      axios
        .get(`/api/tourist/reservations/${confirmationCode}/store-items`)
        .then(({ data }) => {
          commit('updateReservationItems', { confirmationCode, data })
        })
        .catch(err => {
          if (err.response.status === 404) {
            commit('showNotFound')
          }
        })
        .then(() => {})
    },
    getPurchaseHistory({ commit }) {
      axios
        .get(`/api/tourist/store-items/history`)
        .then(({ data }) => {
          commit('updatePurchaseHistory', data)
        })
        .catch(err => {
          console.error(err)
        })
        .then(() => {})
    },
    getReservationBasicInfo({ commit }, reservationConfimation) {
      const confirmationCode = encodeURIComponent(reservationConfimation)

      return axios
        .get(`/api/reservations/${confirmationCode}/basic`)
        .then(({ data }) => {
          commit('setReservationBasicInfo', data)
          return data
        })
        .catch(err => {
          console.error(err)
        })
    },
    createReservationSurvey(_store, { confirmation_code, survey }) {
      const confirmationCode = encodeURIComponent(confirmation_code)

      return axios
        .post(`/api/reservations/${confirmationCode}/survey`, survey)
        .then(({ data }) => {
          return data
        })
        .catch(err => {
          console.error(err)
        })
    },
    getTrackedListingTask(context, id) {
      axios
        .get(`/api/tracked-lt/${id}`)
        .then(response => {
          context.commit('updateTrackedListingTask', response.data.payload.data)
        })
        .catch(error => {
          console.log(error)
        })
    },

    async addPhotosToTicket({ dispatch }, { id, images }) {
      try {
        await axios.post(`/api/listing-tasks/${id}/ticket-photos`, {
          images,
        })
        return await dispatch('getTrackedListingTask', id)
      } catch (err) {
        console.log(err)
      }
    },
    async getChekinReservationStatus(_, reservationId) {
      try {
        const response = await axios.get(
          `/api/reservations/verification_details?reservation_id=${reservationId}`
        )
        return response.data
      } catch (err) {
        console.error(err)
      }
    },
  },

  getters: {
    upcomingReservations(state) {
      return state.reservations.filter(
        res => new Date(res.check_in).getTime() > new Date().getTime()
      )
    },
    reservations(state) {
      return orderBy('check_in', 'desc', state.reservations)
    },
    paidReservations(state, getters) {
      return getters.upcomingReservations
        .concat(getters.currentlyStayReservations)
        .filter(
          res =>
            !(
              res.is_merchant &&
              res.balance_due > 0 &&
              res.payment_intents.find(
                intent =>
                  intent.metadata.type === 'accommodation' &&
                  intent.status === 'requires_source'
              )
            )
        )
    },
    pastReservations(state) {
      return state.reservations.filter(
        res => new Date(res.check_out).getTime() < new Date().getTime()
      )
    },
    currentlyStayReservations(state) {
      return state.reservations.filter(
        res =>
          new Date(res.check_out).getTime() >= new Date().getTime() &&
          new Date(res.check_in).getTime() <= new Date().getTime()
      )
    },
    currentReservation(state, getters, rootState) {
      return state.reservations.find(
        r =>
          decodeURIComponent(r.confirmation_code) ===
          decodeURIComponent(rootState.route.params.id)
      )
    },
    isTouristLoading(state) {
      return state.loading
    },
    reservationItems(state, getters, rootState) {
      return state.reservationItems[rootState.route.params.id] || []
    },
    reservationInfo(state) {
      return (
        state.tripInfo || {
          info: {},
          car_info: {},
          special_reqeusts: {},
          guests_info: [],
        }
      )
    },
    purchaseHistory(state) {
      return state.purchaseHistory
    },
  },
}
