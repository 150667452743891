<template>
  <div
    v-if="!reservationsFetched"
    class="d-flex align-center flex fill-height justify-center"
  >
    <DvrLoader />
  </div>
  <v-card v-else elevation="0" class="px-sm-10 flex">
    <div class="text-h5 font-weight-medium pa-3">{{ $t('MyTrips') }}</div>
    <v-tabs
      v-model="tab"
      :fixed-tabs="isMobile"
      background-color="white"
      color="black"
    >
      <v-tab class="text-caption text-sm-subtitle-2" href="#all">{{
        $t('All')
      }}</v-tab>
      <v-tab
        class="text-caption text-sm-subtitle-2"
        :disabled="!upcomingReservations.length"
        href="#upcoming"
      >
        {{ $t('Upcoming') }}
      </v-tab>
      <v-tab
        class="text-caption text-sm-subtitle-2"
        :disabled="!pastReservations.length"
        href="#past"
      >
        {{ $t('Past') }}
      </v-tab>
      <v-tabs-items v-model="tab" class="pt-3">
        <v-tab-item value="all">
          <v-row
            v-if="reservations.length"
            class="justify-center justify-sm-start"
          >
            <v-col
              v-for="reservation in reservations"
              :key="reservation.id"
              cols="auto"
              class="pa-5 px-8 px-sm-5"
              sm="6"
              md="5"
              lg="3"
            >
              <TripThumbnail :reservation="reservation" />
            </v-col>
          </v-row>
          <!--          <v-row v-else class="align-center flex justify-center">-->
          <!--            <v-card elevation="0" class="pa-5 text-center">-->
          <!--              <v-img src="@/assets/images/draw-vacation.jpg" max-width="600" />-->
          <!--              <div class="text-subtitle-1 font-weight-medium ma-5">-->
          <!--                Don't waste your time and start exploring-->
          <!--                {{ companyName }} vacation-->
          <!--              </div>-->
          <!--              <v-btn-->
          <!--                link-->
          <!--                href="https://designedvr.com"-->
          <!--                target="_blank"-->
          <!--                color="primary"-->
          <!--                class="btn-nudge pa-3"-->
          <!--              >-->
          <!--                Explore {{ companyName }}-->
          <!--              </v-btn>-->
          <!--            </v-card>-->
          <!--          </v-row>-->
        </v-tab-item>
        <v-tab-item value="upcoming">
          <v-row
            v-if="upcomingReservations.length"
            class="justify-center justify-sm-start"
          >
            <v-col
              v-for="reservation in upcomingReservations"
              :key="reservation.id"
              class="pa-5 px-8 px-sm-5"
              sm="6"
              md="5"
              lg="3"
              cols="auto"
            >
              <TripThumbnail :reservation="reservation" />
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item value="past">
          <v-row class="justify-center justify-sm-start">
            <v-col
              v-for="reservation in pastReservations"
              :key="reservation.id"
              class="pa-5 px-8 px-sm-5"
              sm="6"
              md="5"
              lg="3"
              cols="auto"
            >
              <TripThumbnail disabled :reservation="reservation" />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import TripThumbnail from './trip-thumbnail'
import deviceMixin from '@/components/mixins/deviceMixin'
import DvrLoader from '@/assets/icons/dvr-loader'
export default {
  name: 'HomePage',
  components: {
    DvrLoader,
    TripThumbnail,
  },
  mixins: [deviceMixin],
  data() {
    return {
      tab: 'all',
    }
  },

  computed: {
    reservationsFetched() {
      return this.$store.state.tourist.reservationsFetched
    },
    ...mapGetters([
      'upcomingReservations',
      'pastReservations',
      'reservations',
      'currentlyStayReservations',
    ]),
  },

  updated() {
    if (this.reservationsFetched && this.$route.params.afterLogin) {
      const resInProcess = this.upcomingReservations.find(
        r => r.pre_check_in.status !== 'Done'
      )
      if (resInProcess) {
        this.$router.push({
          name: 'pre-check-in',
          params: { id: resInProcess.confirmation_code },
        })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.reservation-thumbnail {
  transition: all 0.15s ease-in-out;
}
.btn-nudge {
  -webkit-animation: nudge 5s infinite linear;
  animation: nudge 5s infinite linear;
}
@-webkit-keyframes nudge {
  0%,
  90% {
    -webkit-transform: rotate(0deg);
  }
  92% {
    -webkit-transform: rotate(4deg);
  }
  94% {
    -webkit-transform: rotate(-4deg);
  }
  96% {
    -webkit-transform: rotate(4deg);
  }
  98% {
    -webkit-transform: rotate(-4deg);
  }
  100% {
    -webkit-transform: rotate(4deg);
  }
}
@keyframes nudge {
  0%,
  90% {
    transform: rotate(0deg);
  }
  92% {
    transform: rotate(4deg);
  }
  94% {
    transform: rotate(-4deg);
  }
  96% {
    transform: rotate(4deg);
  }
  98% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(4deg);
  }
}
</style>
