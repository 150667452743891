<template>
  <v-main class="app-bar-container">
    <div
      v-if="!userFetched"
      class="d-flex align-center flex fill-height justify-center"
    >
      <DvrLoader />
    </div>
    <TouristBar v-if="$route.query.externalCheckout !== 'true'" />
    <NotFound v-if="pageNotFound" />
    <div v-if="userFetched && !pageNotFound" class="d-flex app-bar-container">
      <router-view />
    </div>
  </v-main>
</template>

<script>
import TouristBar from './tourist-bar'
import featureFlagsMixin from '@/components/mixins/featureFlagsMixin'
import { HTTP_STATUS } from '@/consts'
import get from 'lodash/fp/get'
import NotFound from '@/components/common/not-found'
import hotjar from '@/lib/analytics/hotjar'
import DvrLoader from '@/assets/icons/dvr-loader'
import { mapActions } from 'vuex'
import { HOTJAR_EVENTS } from '@/lib/analytics/hotjar'

export default {
  name: 'TouristPortal',
  components: {
    DvrLoader,
    NotFound,
    TouristBar,
  },
  mixins: [featureFlagsMixin],
  computed: {
    pageNotFound() {
      return this.$store.getters.isNotFound
    },
    userFetched() {
      return this.$store.getters.userFetched
    },
  },
  methods: {
    ...mapActions(['logAction']),
  },

  async mounted() {
    try {
      const id = this.$router.currentRoute.params.id
      if (id) {
        await this.$store.dispatch('validateUser', { id })
      }
      const user = await this.$store.dispatch('getCurrentUser')
      this.$i18n.locale = user.locale

      if (user && user.id) {
        await this.$store.dispatch('getConfiguration')
      }
      hotjar.identify(user)
      const favicon =
        document.querySelector("link[rel='icon']") ||
        document.createElement('link')
      favicon.href =
        user.tenant_id === 1
          ? '/designedvr.svg'
          : `/${get('tenant.name', user)}.png`
      await this.$store.dispatch('getFeatureFlags')
      this.$store.dispatch('getReservations')
      this.$store.dispatch('getAllItems')
      this.logAction({
        eventType: HOTJAR_EVENTS.PORTAL_VISITED,
      })
    } catch (err) {
      const status = get('response.status', err)
      if (status === HTTP_STATUS.NOT_AUTHORIZED) {
        this.$router.push({
          name: 'login',
          params: { status: 'not_authorized' },
        })
      } else if (status === HTTP_STATUS.NOT_FOUND) {
        this.$store.commit('showNotFound')
      }
    }
  },
}
</script>

<style scoped>
.app-bar-container {
  height: 100%;
}
</style>
