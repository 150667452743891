<template>
  <v-card
    width="100%"
    elevation="0"
    class="d-flex flex-column store-item-box fill-height"
  >
    <v-img
      aspect-ratio="1"
      width="100%"
      :max-height="isMobile ? '100px' : '200px'"
      class="rounded"
      :src="formatImageUrl(storeItem.item.image)"
    />
    <div class="flex d-flex flex-column">
      <div class="flex px-3 py-2">
        <div class="text-subtitle-1 black--text font-weight-bold">
          {{ storeItem.item.name }}
        </div>
        <div class="text-caption ellipsis-2">
          {{ storeItem.item.description }}
        </div>
        <div
          class="py-0 mt-1 d-flex font-weight-bold indigo--text text--darken-4 justify-end align-center"
        >
          <span v-if="storeItem.price.total"
            >{{ toCurrency(storeItem.price.total, { currency }) }}
          </span>
          <span v-if="storeItem.price.start_from"
            ><span class="text-caption black--text text--secondary"
              >{{ $t('From') }}:</span
            >
            {{ toCurrency(storeItem.price.start_from, { currency }) }}</span
          >
          <v-tooltip
            v-if="storeItem.status === 'future'"
            color="white"
            top
            max-width="300"
          >
            <template #activator="{ on }">
              <v-icon x-small color="secondary" class="absolute ml-1" v-on="on">
                $info_circle
              </v-icon>
            </template>
            <div class="black--text">
              <div
                v-if="storeItem.status === 'future'"
                class="warning--text py-2 font-weight-medium text-caption"
              >
                * Based on dates availability
              </div>
            </div>
          </v-tooltip>
        </div>
      </div>
    </div>
    <v-card-actions>
      <v-row no-gutters class="flex-wrap">
        <slot />
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import deviceMixin from 'components/mixins/deviceMixin'
import CommonFunctions from 'components/mixins/common-functions'
export default {
  name: 'StoreItemBox',
  mixins: [deviceMixin, CommonFunctions],
  props: ['storeItem', 'currency'],
}
</script>

<style scoped>
.store-item-box {
  border: 1px solid var(--v-secondary-lighten1);
}
.store-item-box:hover {
  box-shadow: 0px 5px 10px #00000012 !important;
}
</style>
